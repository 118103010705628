<template>
  <div>
    <div class="RapportDeVente">
            <h2>Rapport</h2>
            <h1>{{ModeOperation == "Vente" ? "Vente (s)" : ModeOperation == "Recouvrement" ? "Recouvrement" : ModeOperation == "Avoir" ? "Avoir" : ""}}</h1>
            <div v-if="ModeOperation == 'Vente' || ModeOperation === 'Avoir'">
            <div  class="ZoneTable" v-for="Catégory,n in CatégoryHave" :key="n" >
                <h2 style="margin:30px 0px;">{{Catégory.produits[0].categorie}}</h2>
                <p>Les produits</p>
                <table>
                    <tr>
                        <th>Nom</th>
                        <th>Quantité</th>
                        <th>Réduction Commerciale</th>
                    </tr>
                    <tr v-for="Product,n in Catégory.produits" :key="n">
                        <td>{{Product.name}}</td>
                        <td>{{parseFloat(Product.quantiteByUnity).toFixed(2)}}</td>
                        <td>{{Product.escompte}} </td>
                    </tr>
                </table>
            </div>
            </div>

            <div class="ZoneTable"  v-if="ModeOperation !== 'Vente' && ModeOperation !== 'Avoir'">
                <h2 style="margin:30px 0px;">{{CatégoryHave.description}}</h2>
                    <p>Les produits :</p>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Quantité</th>
                            <th>Réduction Commerciale</th>
                        </tr>
                        <tr v-for="Product,n in CatégoryHave.produits" :key="n">
                            <td>{{Product.name}}</td>
                            <td>{{Product.quantite}}</td>
                            <td>{{Product.escompte}} </td>
                        </tr>
                    </table>
            </div>
            <div class="QRCODE">
                QR CODE
            </div>

            <div v-if="ModeOperation === 'Vente'">
                <div class="DownlodPdf" v-for="Catégory,n in CatégoryHave" :key="n">
                    <button @click="DowloadPdf(Catégory.produits[0].categorie)">Telecharger PDF : {{Catégory.produits[0].categorie}}</button>
                </div>
                <div class="DownlodPdf" v-for="Stock,n in AllStockWeHave" :key="n">
                    <button @click="DownloadBonLivraison(Stock, n)">Telecharger BL ({{Stock}})</button>
                </div>
            </div>

            <div v-if="ModeOperation === 'Avoir'">
                <div class="DownlodPdf" v-for="Catégory,n in CatégoryHave" :key="n">
                    <button @click="DowloadPdfAvoir(Catégory.produits[0].categorie)">Telecharger PDF : {{Catégory.produits[0].categorie}}</button>
                </div>
                <div class="DownlodPdf" v-for="Stock,n in AllStockWeHave" :key="n">
                    <button @click="DownloadBonResiption(Stock, n)">Telecharger BR ({{Stock}})</button>
                </div>
            </div>

            <div class="DownlodPdf"   v-if="ModeOperation !== 'Vente' && ModeOperation !== 'Avoir'">
                <button @click="DowloadPdfNoteHaveCategory()">Telecharger PDF </button>
            </div>

    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import axios from "axios";
import API_URL from "../../config";

export default {
 props:["RapportId"],
  data() {
    return {
        CatégoryHave:[],
        AllStockWeHave:[],
        Id:'',
        ForId:'',
        ModeOperation:''
    };
  },
  created() {
  },
  computed: {},
  methods: {
    GetRapportData(){
            axios.get(`${API_URL}/rapport/getOne/${this.RapportId}`).then((response) => {
                this.CatégoryHave = response.data.Rapport
                this.Id = response.data._id,
                this.ForId = response.data.for._id
                this.ModeOperation = response.data.ModeOperation
                let allStock = []
                response.data.MagasinStock.forEach(stock => {
                    stock.forEach(SingleStock =>{
                        if(!(allStock.includes(SingleStock.city)))
                            allStock.push(SingleStock.city)
                    })
                })
                this.AllStockWeHave = allStock
            });
    },
    DowloadPdf(Ctég){
        console.log(Ctég,this.Id,this.ForId)
                let Shor_Catég = ''
                if(Ctég == "Engraisclassiques")
                    Shor_Catég  = 'EC'
                else if (Ctég == "Engraisspeciaux")
                    Shor_Catég  = 'ES'
                else if (Ctég == "Semences")
                    Shor_Catég  = 'S'
                else if (Ctég == "Pesticides")
                    Shor_Catég  = 'P'
                else if (Ctég == "Hygiènepublique")
                    Shor_Catég  = 'HP'
                else if (Ctég == "Vétérinaires")
                {
                    Shor_Catég  = 'V'
                }
                console.log('-------------------------------------')
                console.log('BC F')
                console.log('-------------------------------------')
                window.open(`${API_URL}/PDF/DownloadRapportFromBack/${this.Id}/${this.ForId}/${Shor_Catég}/F`, '_blank');
    },
    DowloadPdfAvoir(Ctég) {
        console.log(Ctég,this.Id,this.ForId)
                let Shor_Catég = ''
                if(Ctég == "Engraisclassiques")
                    Shor_Catég  = 'EC'
                else if (Ctég == "Engraisspeciaux")
                    Shor_Catég  = 'ES'
                else if (Ctég == "Semences")
                    Shor_Catég  = 'S'
                else if (Ctég == "Pesticides")
                    Shor_Catég  = 'P'
                else if (Ctég == "Hygiènepublique")
                    Shor_Catég  = 'HP'
                else if (Ctég == "Additifs")
                {
                    Shor_Catég  = 'V'
                }
                    window.open(`${API_URL}/PDF/DownloadRapportFromBackAvoir/${this.Id}/${this.ForId}/${Shor_Catég}/F/Avoir`, '_blank');
    },
    DownloadBonLivraison(City, index){
        window.open(`${API_URL}/PDF/DownloadRapportFromBack/${this.Id}/${this.ForId}/${City}/BL?indexOf=${index}`, '_blank');
    },
    DownloadBonResiption(City, index) {
        window.open(`${API_URL}/PDF/DownloadRapportFromBackAvoir/${this.Id}/${this.ForId}/${City}/BL/BR?indexOf=${index}`, '_blank');
    },
    DowloadPdfNoteHaveCategory(){
         window.open(`${API_URL}/PDF/downloadFileNotHaveTva/${this.Id}`, '_blank');
    }
  },
  mounted(){
      this.GetRapportData()
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;
.RapportDeVente{
    width:50%;
    left: 30%;
    position: absolute;
    z-index: 100;
    background:white;
    border-radius: 10px;
    box-shadow: 0px 0px 11px -4px;
    text-align: center;
    padding:20px;
    h2,h1{
        font-family:$primaryFont ;
        font-size: 25px;
        font-weight:600;
        color:$primary-background-color;
    }
    h1{
        font-size: 40px !important;
        font-weight: bold;
    }
    p{
        font-size: 25px !important;
        font-weight: 600;
        text-align: start;
        line-height: 30px;
    }
    table{
        width:100%;
        th{
            background-color:#142048;
            font-size: 20px;
            color:white;
            padding:10px 0px;
        }
        td{
            font-size: 20px;
            color:#142048;
            padding:10px 0px;
            border: 1px solid black;
            font-weight: bold;
        }
    }
    .QRCODE{
        padding: 40px;
    }
    .DownlodPdf{
        button{
            padding:10px 40px;
            border-radius: 30px;
            font-weight: bold;
            background-color: green;
            color:white;
                        display:block !important;
            margin: 10px auto;
            &:hover{
                 background-color: transparent;
                color:green;
                border:1px solid green;
            }

        }
    }
}

</style>
