<template>
  <div>
    <div class="BalckPage" v-if="OpenClient" @click="RemoveAllPopup"></div>
    <div class="BalckPage" v-if="OpenClientTwo" @click="RemoveAllPopup"></div>
    <div class="BalckPage" v-if="OpenRapportD" @click="RemoveAllPopup"></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <PopupRapportGeneralInfo
      v-if="OpenRapportD"
      :PropsToRapportPopup="PropsToRapportPopup"
      @Close="CloseFuntion()"
    />

    <PopupClinet
      v-if="OpenClient"
      :PropsToClientPopup="PropsToClientPopup"
      @Close="CloseFuntion()"
    />

    <PopupPDF
      v-if="OpenClientTwo"
      :PropsToPDFPopup="PropsToPDFPopup"
      @Close="CloseFuntion()"
    />

    <div class="ListClient">
      <div class="OuClientAndBtn">
        <h1>Etat des encaissements</h1>
      </div>

      <div class="Filter">
        <div class="FilterParCatég">
          <h6>Modalité de paiement :</h6>
          <select v-model="selectedPayM" @change="GetAllRapportByYear">
              <option value="Tout">Tout</option>
              <option value="Chéque">Chéque</option>
              <option value="Viremment">Viremment</option>
              <option value="Espéce">Espéce</option>
              <option value="Effet">Effet</option>
          </select>
        </div>

        <div class="FilterParCatég">
          <h6>Client :</h6>
          <input type="text" v-model="selectedClientName" @keyup="GetAllRapportByYear" />
        </div>

        <div class="FilterParCatég">
          <h6>Code client :</h6>
          <input type="text" v-model="selectedClientCode" @keyup="GetAllRapportByYear" />
        </div>

        <div class="FilterParCatég">
          <h6>Année :</h6>
          <input type="number" min="1900" max="2099" step="1" v-model="currentYear" @change="GetAllRapportByYear" />
        </div>
      </div>

            <div class="Filter">
          <v-btn
            @click="handleExelDownload"
            color="success"
            fab
            large
            dark
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
      </div>

      <div class="HeaderTable">
        <!-- <h3>Date</h3> -->
        <h3>Date</h3>
        <h3>Référence</h3>
        <h3>Code client</h3>
        <h3>Nom du client</h3>
        <h3>Montant</h3>
        <h3>Modalité de paiement</h3>
        <h3>Montant reçu</h3>
        <h3>Numéro de compte</h3>
      </div>
      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>

      <div v-if="!dataIsLoaded">
        <div class="spinner">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>

      <!-- M22/00645 -->

      <div class="TableContentZone" v-if="dataIsLoaded">
        <div
          class="HeaderBody"
          v-for="(Operation, index) in AllOperationHave"
          :key="index"
          :style=" index % 2 == 0 ? 'background-color: rgba(5,139,132,0.22)' : 'background-color: rgba(180,181,181,0.22);'"
        >
          <h3>{{ Operation.Date }}</h3>
          <h3></h3>
          <h3>{{ Operation.CodeClient }}</h3>
          <h3>{{ Operation.ClientName }}</h3>
          <h3>{{ Operation.Montant }}</h3>
          <h3>{{ Operation.PayMethode }}</h3>
          <h3>{{ Operation.Status == 'V' ? Operation.Montant : '' }}</h3>
          <h3></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import PopupClinet from "@/components/GestionOpPopup/products";
import PopupPDF from "@/components/GestionOpPopup/rapportPDF";
import PopupRapportGeneralInfo from "@/components/GestionOpPopup/PopupRapportGeneralInfo";
import Swal from "sweetalert2";

import axios from "axios";
import API_URL from "../../config";
import SOCKETS_URL from "../../configSocketsURL";
import io from "socket.io-client";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    PopupClinet,
    PopupPDF,
    PopupRapportGeneralInfo,
  },
  data() {
    return {
      OpenClient: false,
      OpenClientTwo: false,
      OpenRapportD: false,
      PropsToClientPopup: {
        Condition: "",
        Id: "",
      },
      PropsToPDFPopup: {
        Condition: "",
        Id: "",
      },
      PropsToRapportPopup: {
        Condition: "",
        Id: "",
      },
      Quantity: 0,
      AllOperationHave: [],
      AllTCP: [],
      TCPFilterd: "Tout",
      ClientFilterd: "",
      ICEFiltred: "",
      CINFiltred: "",
      VideData: false,

      oneTCPInfo: "",

      sockets: io.connect(`${SOCKETS_URL}`),

      dataIsLoaded: false,

      currentYear: new Date().getFullYear(),

      rapportCounter: 1,

      FilterdRapportType: "BL",

      selectedPayM: 'Tout',
      selectedClientName: 'Tout',
      selectedClientCode: 'Tout'
    };
  },
  created() {
    this.sockets.on("getAllFacturationMaroc", (data) => {
      this.GetAllRapport();
    });

    this.sockets.on("getAllFacturationBirot", (data) => {
      this.GetAllRapport();
    });

    this.sockets.on("getAllFacturationRecouvremment", (data) => {
      this.GetAllRapport();
    });

    this.sockets.on("getAllFacturationBirotOnlyData", () => {
      this.GetAllRapport();
    });

    this.sockets.on("getAllFacturationMarocOnlyData", () => {
      this.GetAllRapport();
    });

    this.sockets.on('refreshGestionDesOperationData', () => {
      this.GetAllRapport()
    })

    this.sockets.on('refreshMbarkData', () => {
      this.GetAllRapport()
    })
  },
  computed: {
    // BlNumbers: function() {
    //   return (index, timeString) => {

    //   }
    // }
  },
  methods: {
    GetAllRapportByYear() {
        this.dataIsLoaded = false

        axios.get(`${API_URL}/rapport/strucredForRecouvremment?rapportsYear=${this.currentYear}&clienCode=${this.selectedClientCode}&clienName=${this.selectedClientName}&payMethode=${this.selectedPayM}`)
        .then((response) => {
          this.AllOperationHave = response.data;
        }).then(() => {
          this.dataIsLoaded = true
        })
    },

    handleExelDownload() {
        this.dataIsLoaded = false

        axios.get(`${API_URL}/rapport/strucredForRecouvremmentToXcel?rapportsYear=${this.currentYear}&clienCode=${this.selectedClientCode}&clienName=${this.selectedClientName}&payMethode=${this.selectedPayM}`)
        .then((response) => {
            window.open(`${SOCKETS_URL}/${response.data}`)
            // console.log(`${SOCKETS_URL}/${response.data}`)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.dataIsLoaded = true
        })
    }
  },
  mounted() {
    this.GetAllRapportByYear()
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00ABC4;
.BalckPage{
    background-color:rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.ListClient{
        width: 80%;
        float: left;
        margin-top: 4%;
        ::-webkit-scrollbar {
        width: 10px;
        position: relative;
        top: 20px;
        border-radius: 30px;
    }
    /* Track */
    
     ::-webkit-scrollbar-track {
        background-color:  rgb(231, 231, 231);
        border-radius: 10px;
    }
    /* Handle */
    
     ::-webkit-scrollbar-thumb {
        background:  #b41206;
        border-radius: 10px;
    }
    .TableContentZone{
        height:500px;
        overflow-y: scroll;
    }
    .spinner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .OuClientAndBtn{
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 80%;
        margin: auto;
        button{
            font-size:17px;
            font-family: $primaryFont;
            font-weight: 500;
            border-radius: 10px;
            padding:6px 30px;
            background-color: #E1E1E1;
            color:#00ABC4;
            &:hover{
                border: 1px solid $primary-background-color;
                background-color:transparent;
                color:$primary-background-color;
            }
        }
    }
    h1,h3{
        font-family:$primaryFont ;
        font-size: 40px;
        font-weight:bold;
        color:$primary-background-color;
        text-align:center;
        padding-bottom: 30px;
    }

    .HeaderTable {
      h3 {
        height: 60px;
        padding: 10px !important;
      }
    }

    .HeaderTable,.HeaderBody{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0px auto;

        h3{
            font-size: 17px;
            border: 1px solid white;
            padding:10px 0px;
            background-color:#058B84;
            color:white;
            font-weight: 600;
            width:400px;
            line-height: 20px;
            &:first-of-type {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
            &:last-of-type {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }
    .HeaderBody{
        margin-bottom: 10px;
        border-radius: 20px;
        h3{
           font-size: 17px;
            padding:10px 0px;
            color:white;
            font-weight: 600;
            width:200px;
            overflow-wrap: break-word;
            background-color:transparent;
            border: none;
            color: #058B84;
            margin: 0px;    
        }
        svg{
            fill: #058B84;
            width:30px;
            height:30px;
            margin: 0px 10px;
            &:hover{
                fill: $primary-background-color;
                cursor: pointer;
            }
        }
    }
    .Filter{
        display:flex;
        justify-content:space-around;
        align-items:center;
        width:80%;
        margin:30px  auto;
        .FilterParCatég {
            h6 {
                color: $primaryColor;
            }
        }
        h6{
        font-family:$primaryFont ;
        font-size: 18px;
        font-weight:bold;
        color:#142048;
        padding-bottom: 5px;
        }
        select,Input{
                border: 1px solid #035a7f;
                width:100%;
                padding:6px  ;
                border-radius: 6px;
                cursor: pointer;
        }
    }
    .Vide{
        width:80%;
        margin:30px  auto;
        background-color: #fcd309;
        padding:10px 0px;
        text-align: center;
        h5{
            font-size: 20px;
            font-weight: 600;
            color:#035a7f;
        }
    }
}
</style>
