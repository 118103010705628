<template>
  <div>
    <IncludedNavBar />
    <div class="login">
      <!-- <router-link to="/generale" class="creeCompt">
        <p>CREE UN COMPTE</p>
      </router-link> -->
      <div class="contents">
        <img src="../assets/logo.png" class="mb-5" width="160px" />
        <h5>
          AUTHENTIFICATION DV <br> ACCEDEZ A VOTRE ARDUNIFER
        </h5>
          <form autocomplete="off">
            <input
              type="text"
              v-model="email"
              label="votre email"
              autocomplete="false"
              outlined
            >
            <input
              type="password"
              v-model="motDePass"
              label="votre mot de pass"
              autocomplete="false"
              outlined
            >
            <button
              class="pa-7 submitBtn"
              outlined
              color="#1cb6b6"
              @click.prevent="checkValidUser"
            >
              S'identifier
            </button>
          </form>

      </div>
      <p class="copy">COPYRIGHT &copy; {{new Date().getFullYear()}} | <span>searchcept</span></p>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar"
import jwt_decode from "jwt-decode";
import Vue from "vue";
import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar
  },
  data() {
    return {
      email: "",
      motDePass: ""
    }
  },
  methods: {
    // redirectFun() {
    //   this.$router.push('/userlists')
    // },
      checkValidUser() {
        axios
        .post(`${API_URL}/user/signIn`, {
          Email: this.email,
          Password: this.motDePass
        })
        .then((response) => {
          var token = response.data.token;
          var decoded = jwt_decode(token);

          if(decoded.role != 2 && decoded.role != 3 && decoded.role != 0 && decoded.role != 1) {
            Vue.$toast.open({
              message:"veuillez entrer une e-mail et mot de passe correct",
              type: "error"
            });
            return
          }

          localStorage.setItem("user_id2", decoded._id)
          localStorage.setItem("token2", response.data.token)

          // console.log(decoded.role, response.data.token)
          // console.log("userRole", decoded.userRole)

          // this.$cookies.set("user_D", decoded.role)
          if(decoded.userRole == "Gestionnaire de stocks")
            this.$router.push({ path: "/GestiondeStock" })
          else if (decoded.userRole == "Facturation")
            this.$router.push({ path: "/Facturation" })
          else if (decoded.userRole == "Achat national")
            this.$router.push({ path: "/AchatNationnal" })
          else if (decoded.userRole == "Technical Department")
            this.$router.push({ path: "/GestionStockVE" })
          else if (decoded.userRole == "relation client")
            this.$router.push({ path: "/LC" })

          else if (decoded.userRole == "D.Commercial")
            this.$router.push({ path: "/Frais_deplacement" })
          else if (decoded.userRole == "D.General")
            this.$router.push({ path: "/Frais_DG" })

          else if (decoded.userRole == "parc auto")
            this.$router.push({ path: "/Charges_vehicules" })
              // if(decoded._id === "63ce83c9e19244590753cda0")
              //   this.$router.push({ path: "/AchatNationnal" })
              // else
              //   this.$router.push({ path: "/LVJ" })
          // decoded.role == 2 ? this.$router.push({ path: "/GestiondeStock" }) : decoded.role == 3 ? this.$router.push({ path: "/Facturation" }) : this.$router.push({ path: "/LVJ" });
          })
          .catch(err => {
            Vue.$toast.open({
              message: err.response.data.error,
              type: "error"
            });
        });
      }
  },
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #3f3f41;
$primary-background-color: #b41206;

$grayColor: #707070;
$redColor: #00abc4;

%connetionAndInscription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 90vh;
  background-image: url("../assets/loginBack.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5%;
  @media (max-width: 990px) {
    padding-top: 15%;
  }
}
.login {
  .v-text-field--outlined fieldset {
    top: -10px;
    bottom: 10px
  }
  .v-text-field.v-text-field--enclosed {
    margin: 0;
    padding: 0;
    width: 40%;
    @media(max-width: 712px) {
      width: 90%;
    }
  }
  @extend %connetionAndInscription;
  .creeCompt {
    align-self: flex-end;
    margin-right: 10%;
    color: black;
    font-family: $primaryFont;
    font-weight: bold;
    font-size: 22px;
    position: relative;
    top: -6%;
    &::before {
      content: "";
      background-color: black;
      width: 25px;
      height: 4px;
      position: absolute;
      left: -40px;
      top: 15px;
    }
    &::after {
      content: "";
      background-color: black;
      width: 25px;
      height: 4px;
      position: absolute;
      right: -40px;
      top: 15px;
    }
  }
  .contents {
    margin-top: -2%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    img {
      width: 250px;
    }
    h5 {
      color: $redColor;
      font-family: $primaryFont;
      text-align: center;
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 2%;
    }
    form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
      input {
        width: 47%;
        border: 1px solid $grayColor;
        border-radius: 30px;
        padding: .7%;
        margin-bottom: 1%;
        box-shadow: 1px 3px 8px -4px $grayColor;
        // background-image: linear-gradient(#FF0000, #FF0000);
        background-image: linear-gradient(rgba($redColor, .3), rgba($redColor, .1));
        background-position: 50% 50%;
        background-size: 0% 100%;
        transition: all .4s ease-in-out;
        &:focus-visible {
          border: 1px solid $grayColor;
          outline: unset;
          background-size: 100% 100%;
        }
      }
      .submitBtn {
        color: $redColor;
        font-size: 25px;
        border: 1px solid $grayColor;
        border-radius: 30px;
        padding: .2% 3% !important;
        background-image: linear-gradient(rgba($redColor, .3), rgba($redColor, .1));
        background-position: 50% 50%;
        background-size: 0% 100%;
        transition: all .4s ease-in-out;
        &:hover {
          background-size: 100% 100%;
        }
      }
    }
  }
  .copy {
    color: $primaryColor2;
    font-weight: bold;
    font-family: $primaryFont;
    position: absolute;
    bottom: 20px;
    font-size: 20px;
    left: 5%;
    bottom: 5%;
    span {
      color: $primary-background-color;
    }
  }
}
</style>