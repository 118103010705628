<template>
    <div class="recovrementPopup">

        <div>
            <v-row justify="space-around">
                <v-col cols="auto">

                </v-col>
            </v-row>
        </div>

        <div v-if="RecouvrementD2">
            <p><span>Mode</span><span> : </span>Chèque</p>
            <!-- <p><span>Montant total</span><span> : </span>{{sumOfAmount.toFixed(2)}}</p> -->
            <p><span>Montant total</span><span> : </span>{{ D2Amount }}</p>
            <div>
                <table>
                    <tr>
                        <th>Banque</th>
                        <th>N° Chèque</th>
                        <th>Montant</th>
                        <th>Date</th>
                    </tr>
                    <tr v-for="(chequeInfo, index) in RecouvrementD2.ChequeInfo" :key="index">
                        <td>{{ chequeInfo.Bank }}</td>
                        <td>{{ chequeInfo.Ncheque }}</td>
                        <td>{{ chequeInfo.Montant }}</td>
                        <td class="DateEcheanceTD">
                            {{ chequeInfo.DateEcheance }}
                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
                                </v-btn>

                                </template>
                                <template v-slot:default="dialog">
                                <v-card>
                                    <v-card-text>
                                    <div class="text-h2 pa-12">
                                        <v-row justify="center">
                                            <v-date-picker v-model="pickerDateOne"></v-date-picker>
                                            <v-btn class="mt-5" @click="editeDate('Cheque', index)">Modifier</v-btn>
                                        </v-row>
                                    </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </template>
                            </v-dialog>

                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dateListMeth('Cheque', index)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM128 288c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm32-128c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM128 384c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224z"/></svg>
                                </v-btn>

                                </template>
                                <template v-slot:default="dialog">
                                <v-card>
                                    <v-card-text>
                                    <div class="text-h2 pa-12">
                                        <v-alert
                                            border="bottom"
                                            color="pink darken-1"
                                            dark
                                                v-for="(dateList, index) in dateList" :key="index"
                                            >
                                            {{ dateList }}
                                        </v-alert>
                                    </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </template>
                            </v-dialog>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div v-if="RecouvrementD3">
            <p><span>Mode</span><span> : </span>Effet</p>
            <p><span>Montant total</span><span> : </span>{{ D3Amount }}</p>
            <div>
                <table>
                    <tr>
                        <th>Banque</th>
                        <th>N° Effet</th>
                        <th>Montant</th>
                        <th>Date</th>
                    </tr>
                    <tr v-for="(effetInfo, index) in RecouvrementD3.EffetInfo" :key="index">
                        <td>{{ effetInfo.Bank }}</td>
                        <td>{{ effetInfo.NEffet }}</td>
                        <td>{{ effetInfo.Montant }}</td>
                        <td class="DateEcheanceTD">
                            {{ effetInfo.DateEcheance }}

                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
                                </v-btn>

                                </template>
                                <template v-slot:default="dialog">
                                <v-card>
                                    <v-card-text>
                                        <div class="text-h2 pa-12">
                                            <v-row justify="center">
                                                <v-date-picker v-model="pickerDateTwo"></v-date-picker>
                                                <v-btn class="mt-5" @click="editeDate('Effet', index)">Modifier</v-btn>
                                            </v-row>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                        <v-btn
                                            text
                                            @click="dialog.value = true"
                                        >Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </template>
                            </v-dialog>

                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dateListMeth('Effet', index)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM128 288c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm32-128c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM128 384c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224z"/></svg>
                                </v-btn>

                                </template>
                                <template v-slot:default="dialog">
                                <v-card>
                                    <v-card-text>
                                    <div class="text-h2 pa-12">
                                        <template v-if="dateList && dateList.length != 0">
                                            <v-alert
                                                border="bottom"
                                                color="pink darken-1"
                                                dark
                                                    v-for="(dateList, index) in dateList" :key="index"
                                                >
                                                {{ dateList }}
                                            </v-alert>
                                        </template>

                                        <template v-else-if="!dateList || dateList.length == 0">
                                            <v-alert
                                                border="bottom"
                                                color="pink darken-1"
                                                dark
                                                >
                                                Vide (aucun date d'état d'encaissement)
                                            </v-alert>
                                        </template>

                                    </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        @click="dialog.value = true"
                                    >Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </template>
                            </v-dialog>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div v-if="RecouvrementD4">
            <p><span>Mode</span><span> : </span>Espéce</p>
            <div>
                <table>
                    <tr>
                        <th>N° de transaction</th>
                        <th>Montant</th>
                    </tr>
                    <tr>
                        <td> {{ RecouvrementD4.Nfacture }} </td>
                        <td> {{ parseFloat(RecouvrementD4.montant).toFixed(2) }} </td>
                    </tr>
                </table>
            </div>
        </div>

        <div v-if="RecouvrementD1">
            <p><span>Mode</span><span> : </span>Viremment</p>
            <div>
                <table>
                    <tr>
                        <th>N° de transaction</th>
                        <th>Montant</th>
                    </tr>
                    <tr>
                        <td> {{RecouvrementD1.Ncheque}} </td>
                        <td> {{ parseFloat(RecouvrementD1.montant).toFixed(2) }} </td>
                    </tr>
                </table>
            </div>
        </div>

        <p style="text-align: center">Montant total: {{ sumOfAllAmount }}</p>
    </div>
</template>

<script>

import axios from "axios";
import API_URL from "../../config";

export default {
    props:["RapportId", "RecouvrementData"],
    data() {
        return {
            RecouvrementD1: false,
            RecouvrementD2: false,
            RecouvrementD3: false,
            RecouvrementD4: false,

            RecouvrementObject: false,

            // sumOfAmount: 0
            pickerDateOne: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            pickerDateTwo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            dateList: false
        }
    },
    computed: {
        D1Amount() {
            if(this.RecouvrementD1)
                return parseFloat(this.RecouvrementD1.montant).toFixed(2)
            return 0
        },
        D2Amount() {
            if(this.RecouvrementD2)
                // return this.RecouvrementD2.ChequeInfo.reduce((a, b) => a + b.Montant != "" ? parseFloat(b.Montant) : 0, 0).toFixed(2)
                return this.RecouvrementD2.ChequeInfo.reduce((a, b) => a + parseFloat(b.Montant == "" ? 0 : parseFloat(b.Montant)), 0).toFixed(2)
            return 0
        },
        D3Amount() {
            if(this.RecouvrementD3)
                return this.RecouvrementD3.EffetInfo.reduce((a, b) => a + parseFloat(b.Montant == "" ? 0 : parseFloat(b.Montant)), 0).toFixed(2)
            return 0
        },
        D4Amount() {
            if(this.RecouvrementD4)
                return parseFloat(this.RecouvrementD4.montant).toFixed(2)
            return 0
        },
        sumOfAllAmount() {
            let sumOfAmount = 0
            sumOfAmount = parseFloat(this.D1Amount) + parseFloat(this.D2Amount) + parseFloat(this.D3Amount) + parseFloat(this.D4Amount)
            return sumOfAmount.toFixed(2)
        }
    },
    created() {
        // console.log("rapport ID", this.RapportId)
        console.log("rapport data", this.RecouvrementData)
        if(this.RecouvrementData.Cheque && this.RecouvrementData.Cheque.ChequeInfo.length != 0 ) {
            this.RecouvrementD2 = this.RecouvrementData.Cheque
            // this.RecouvrementD2.ChequeInfo.map(data => this.sumOfAmount += parseFloat(data.Montant))
        }
        if(this.RecouvrementData.Effet && this.RecouvrementData.Effet.EffetInfo && this.RecouvrementData.Effet.EffetInfo.length != 0 ) {
            this.RecouvrementD3 = this.RecouvrementData.Effet
            // this.RecouvrementD3.EffetInfo.map(data => this.sumOfAmount += parseFloat(data.Montant))
        }
        if(this.RecouvrementData.Espece && this.RecouvrementData.Espece.montant != "" ) {
            this.RecouvrementD4 = this.RecouvrementData.Espece
            // this.sumOfAmount = Number.parseFloat(this.RecouvrementD4.montant)
        }
        if(this.RecouvrementData.Virement && this.RecouvrementData.Virement.montant != "") {
            this.RecouvrementD1 = this.RecouvrementData.Virement
            // this.sumOfAmount = Number.parseFloat(this.RecouvrementD1.montant)
        }

        this.RecouvrementObjectMethod()
    },
    methods: {
        RecouvrementObjectMethod() {
            axios.get(`${API_URL}/rapport/getOneRecovrementObject/${this.RapportId}`)
            .then(res => {
                this.RecouvrementObject = res.data
            })
        },
        dateListMeth(OP, index) {
            if(OP == "Cheque") {
                this.dateList = this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList
            }
            if(OP == "Effet") {
                this.dateList = this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList
            }
        },
        editeDate(OP, index) {
            if(OP == "Cheque") {
                const [year, month, day] = this.pickerDateOne.split('-')
                this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateEcheance = `${day}-${month}-${year}`

                if(this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList)
                    this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList.push(`${day}-${month}-${year}`)
                else {
                    this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList = [`${day}-${month}-${year}`]
                }

                this.$swal({
                    title: "Voulez vous modifier?",
                    showDenyButton: true,
                    confirmButtonText: `Modifier`,
                    denyButtonText: `Annuler`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                    axios.put(`${API_URL}/rapport/updateRecouvrementDate/${this.RapportId}`, this.RecouvrementObject).then(() => {
                        this.RecouvrementObjectMethod()
                        this.$swal("cette operation a été modifier!", "", "success");
                        this.$emit('closePopup')
                    });
                    } else if (result.isDenied) {
                        this.$swal("cette operation a été annuler", "", "info");
                    }
                });
            }
            if(OP == "Effet") {
                // this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateEcheance = this.pickerDateTwo

                const [year, month, day] = this.pickerDateTwo.split('-')
                this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateEcheance = `${day}-${month}-${year}`

                if(this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList)
                    this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList.push(`${day}-${month}-${year}`)
                else {
                    this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList = [`${day}-${month}-${year}`]
                }

                this.$swal({
                    title: "Voulez vous modifier?",
                    showDenyButton: true,
                    confirmButtonText: `Modifier`,
                    denyButtonText: `Annuler`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                    axios.put(`${API_URL}/rapport/updateRecouvrementDate/${this.RapportId}`, this.RecouvrementObject).then(() => {
                        this.RecouvrementObjectMethod()
                        this.$swal("cette operation a été modifier!", "", "success");
                        this.$emit('closePopup')
                    });
                    } else if (result.isDenied) {
                        this.$swal("cette operation a été annuler", "", "info");
                    }
                });
            }
        }
    }
    }
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #058B84;

.recovrementPopup {
    table {
        tr {
            .DateEcheanceTD {
                display: flex;
                justify-content: space-around;
                svg {
                    fill: $primaryColor2;
                    padding: 1%;
                    width: 33px;
                    height: 33px;
                    cursor: pointer;
                }
            }
        }
    }
}

.RapportDeVente{
    height: 800px;
    overflow-y: scroll;
    width:50%;
    left: 30%;
    position: absolute;
    z-index: 100;
    background: linear-gradient(180deg, #f3f3f3 30%, #dfdfdf 100%);
    border-radius: 10px;
    box-shadow: 0px 0px 11px -4px;
    text-align: center;
    padding:20px;
    top: 5%;
    // @media (max-width: 1600px) {
    //     height: 500px;
    // }
    h2,h1{
        font-family:$primaryFont ;
        font-size: 25px;
        font-weight:600;
        color:$primary-background-color;
    }
    h1{
        font-size: 40px !important;
        font-weight: bold;
    }
    p{
        font-size: 25px !important;
        font-weight: 600;
        text-align: start;
        line-height: 30px !important;
    }
    .ZoneTable{
        margin-bottom: 40px;
        p {
            color: #058B84;
        }
    }
    table{
        width:100%;
        th{
            background-color:#058B84;
            font-size: 20px;
            color:white;
            padding:10px 0px;
        }
        td{
            font-size: 20px;
            color:#058B84;
            padding:10px 0px;
            border: 1px solid #058B84;
            font-weight: bold;
        }
    }
    .QRCODE{
        padding: 40px;
    }
    .DownlodPdf{
        button{
            padding:10px 40px;
            border-radius: 30px;
            font-weight: bold;
            background: linear-gradient(90deg, rgba(5,139,132,1) 30%, rgba(0,0,0,1) 100%);
            color: #FFFFFF;
            display:block !important;
            margin: 10px auto;
            &:hover{
                background: transparent;
                color:#00abc4;
                border:1px solid #00abc4;
            }

        }
    }
}
</style>
