<template>
    <div class="PopupNewClient productsDetailsPop">
            <h4 v-if="PropsToClientPopup.Condition == 'New'">Nouveau</h4>
            <h4 v-else-if="PropsToClientPopup.Condition == 'Update'">Mettre à jour</h4>
            <h4 v-else-if="PropsToClientPopup.Condition == 'Show'">Aperçu (Stock)</h4>

            <!-- <div class="spinner" v-if="!getTheData">
                <v-progress-circular
                    :size="100"
                    :width="7"
                    color="purple"
                    indeterminate
                ></v-progress-circular>
            </div> -->

            <button v-if="PropsToClientPopup.Condition == 'New'">Valider</button>
            <button v-else-if="PropsToClientPopup.Condition == 'Update'">Mettre à jour</button>
    </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import Vue from "vue";
import axios from "axios";
import API_URL from "@/../config";

export default {
    emit:['Close'],
    props:['PropsToClientPopup'],
    components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
        getTheData: false
    };
  },
  created() {
  },
  computed: {},
  methods: {

  },
  mounted(){

  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.productsDetailsPop {
    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .sumary {
        font-weight: bold;
        color: #757575;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        h5 {
            display: flex;
            span {
                width: 200px;
            }
        }
    }
    .myCard {
        margin-bottom: 20px;
    }
    .productsTitle {
        font-size: 25px;
    }
    .productsDetails {
        font-size: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        span {
            font-weight: bold;
            font-size: 15px;
            margin-right: 30px;
            width: 100px;
            color: black;
        }
    }
}

.PopupNewClient{
    position:absolute;
    width:40%;
    padding:40px;
    border-radius: 10px;
    background-color:white;
    box-shadow: 0px 0px 11px -4px black;
    left: 30%;
    top: 10%;
    z-index: 100000;
    overflow: auto;
    height: 80%;
    h4{
        font-family:$primaryFont ;
        font-size: 30px;
        font-weight:bold;
        color:$primary-background-color;
        padding-bottom: 30px;
    }
    button{
            font-size:17px;
            font-family: $primaryFont;
            font-weight:700;
            border-radius: 10px;
            padding:6px 30px;
            background-color:$primary-background-color;
            color:white;
            &:hover{
                border: 1px solid $primary-background-color;
                background-color:transparent;
                color:$primary-background-color;
            }
    }
    .Form{
        display:flex;
        align-items: start;
        justify-content: space-between;
        .LeftInputs,.RightInputs{
            width:100%;
            .InputZone{
                margin: 20px 0px;
                h6{
                    font-family:$primaryFont ;
                    font-size:20px;
                    font-weight:700;
                    color:#142048;
                }
            }
            .InputFaild{
                border: 1px solid #035a7f;
                width:90%;
                padding:6px  ;
                border-radius: 6px;
                display: flex;
                svg{
                    margin-right: 5px;
         
                }
                input{
                    width:100%;
                    background-color:transparent;
                    border: none;
                    outline: none;
                }
            }
        }
    }
    .TCP{
        margin-bottom: 20px;
        h6{
            font-family:$primaryFont ;
            font-size:20px;
            font-weight:700;
            color:#142048;
        }

        select{
                border: 1px solid #035a7f;
                width:95%;
                padding:6px  ;
                border-radius: 6px;
                cursor: pointer;
        }
    }
    .BtnPopUp{
        width:95%;
        text-align: end;
    }
    .Personne{
        display: flex;
        align-items: center;
        p{
                    margin-right: 50px;
                    font-family:$primaryFont ;
                    font-size:20px;
                    font-weight:700;
                    color:#142048 !important;
        }
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #142048;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
    }
    @media screen and (max-width:  1840px) {

        }
    @media screen and (max-width:  1640px) {
        padding:20px 40px !important;
        width:60%;
        top:2%;
        left:20%;
      }
    @media screen and (max-width:  1440px) {
        h4{
            font-size:20px;
            padding-bottom: 10px !important;
        }
        h6{
            font-size:15px !important;
        }
        p{
            font-size:15px !important;
        }
        input,select,textarea{
            padding:3px !important;
        }
        .InputZone{
            padding: 3px ;
            margin: 10px 0px !important;
        }
      }

}
</style>

