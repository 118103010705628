<template>
  <div class="PopupNewClient">
    <h4 v-if="PropsToClientPopup.Condition == 'New'">Nouveau Client</h4>
    <h4 v-else-if="PropsToClientPopup.Condition == 'Update'">
      Mettre à jour un Client
    </h4>
    <h4 v-else-if="PropsToClientPopup.Condition == 'Show'">Aperçu un Client</h4>
    <div class="TCP">
      <div class="InputZone">
        <h6>TCP</h6>
        <div class="InputFaild">
          <select
            v-model="TCPchoose"
            :style="
              TCPchoose == 'Select un TCP' && Submit == true
                ? 'border:2px solid red'
                : ''
            "
            :disabled="PropsToClientPopup.Condition === 'Show'"
          >
            <option value="Select un TCP">Select un TCP</option>
            <option v-for="(TCP, n) in AllTCP" :key="n" :value="TCP.Id">
              {{ TCP.Name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="Personne">
      <p>
        <input
          type="radio"
          id="test1"
          name="radio-group"
          :checked="Personne == 'Morale'"
          @click="Personne = 'Morale'"
          :disabled="PropsToClientPopup.Condition === 'Show'"
        />
        <label for="test1">Entreprise</label>
      </p>
      <p>
        <input
          type="radio"
          id="test2"
          name="radio-group"
          :checked="Personne == 'Physique'"
          @click="Personne = 'Physique'"
          :disabled="PropsToClientPopup.Condition === 'Show'"
        />
        <label for="test2">Personne physique</label>
      </p>
    </div>

    <div class="PrsonnePhysique">
      <div class="Form">
        <div class="LeftInputs">
          <div class="InputZone">
            <h6>Code client</h6>
            <div
              class="InputFaild"
              :style="
                CodeCliet == '' && Submit == true ? 'border:2px solid red' : ''
              "
            >
              <input
                type="number"
                placeholder="Code client"
                v-model="CodeCliet"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone">
            <h6>Téléphone</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="Téléphone"
                v-model="Téléphone"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone">
            <h6>Mail</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="Mail"
                v-model="Mail"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
            <div class="InputZone">
              <h6>Responsable</h6>
              <div class="InputFaild">
                <input
                  type="text"
                  placeholder="Responsable"
                  v-model="Responsable"
                  :disabled="PropsToClientPopup.Condition === 'Show'"
                />
              </div>
            </div>

            <div class="InputZone">
              <h6>Echéance 1</h6>
              <div class="InputFaild">
                <input
                  type="number"
                  placeholder="Echéance 1"
                  v-model="Deadline1"
                  :disabled="PropsToClientPopup.Condition === 'Show'"
                />
              </div>
            </div>

            <div class="InputZone">
              <h6>Echéance 2</h6>
              <div class="InputFaild">
                <input
                  type="number"
                  placeholder="Echéance 2"
                  v-model="Deadline2"
                  :disabled="PropsToClientPopup.Condition === 'Show'"
                />
              </div>
            </div>

            <div class="InputZone">
              <h6>Etat de client (Débit)</h6>
              <div class="InputFaild">
                <input
                  type="number"
                  placeholder="Etat de client"
                  v-model="ClientSumOffFPrice"
                  :disabled="PropsToClientPopup.Condition === 'Show'"
                />
              </div>
            </div>

          <div class="InputZone">
            <h6>Code comptable</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="Code comptable"
                v-model="CodeComptable"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>

          </div>
        </div>
        <div class="RightInputs">
        <!-- entrprise -->
          <div class="InputZone" v-if="Personne == 'Morale'">
            <h6>Raison sociale</h6>
            <div
              class="InputFaild"
              :style="
                RaisonSociel == '' && Submit == true
                  ? 'border:2px solid red'
                  : ''
              "
            >
              <input
                type="text"
                placeholder="Raison sociale"
                v-model="RaisonSociel"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone" v-if="Personne == 'Morale'">
            <h6>ICE</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="ICE"
                v-model="ICE"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone" v-if="Personne == 'Morale'">
            <h6>Adresse</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="Adresse"
                v-model="Adresse"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone" v-if="Personne == 'Morale'">
            <h6>Téléphone du Responsable</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="Téléphone Responsable"
                v-model="Responsable_Téléphone"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>

        <!-- physic -->
          <div class="InputZone" v-if="Personne == 'Physique'">
            <h6>Prénom et nom</h6>
            <div
              class="InputFaild"
              :style="
                PrénomAndNom == '' && Submit == true
                  ? 'border:2px solid red'
                  : ''
              "
            >
              <input
                type="text"
                placeholder="Prénom et nom"
                v-model="RaisonSociel"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone" v-if="Personne == 'Physique'">
            <h6>CIN</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="CIN"
                v-model="ICE"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone" v-if="Personne == 'Physique'">
            <h6>Adresse</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="Adresse"
                v-model="Adresse"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>
          <div class="InputZone" v-if="Personne == 'Physique'">
            <h6>Numero de Téléphone</h6>
            <div class="InputFaild">
              <input
                type="text"
                placeholder="Numero de Téléphone"
                v-model="Responsable_Téléphone"
                :disabled="PropsToClientPopup.Condition === 'Show'"
              />
            </div>
          </div>

            <div class="InputZone">
                <h6>Plafond 1</h6>
                <div class="InputFaild">
                    <input
                    type="number"
                    placeholder="Plafond 1"
                    v-model="Plaphon_one"
                    :disabled="PropsToClientPopup.Condition === 'Show'"
                    />
                </div>
            </div>
            <div class="InputZone">
                <h6>Plafond 2</h6>
                <div class="InputFaild">
                    <input
                    type="number"
                    placeholder="Plafond 2"
                    v-model="Plaphon_two"
                    :disabled="PropsToClientPopup.Condition === 'Show'"
                    />
                </div>
            </div>

            <div class="InputZone">
              <h6>Etat de client (Crédit)</h6>
              <div class="InputFaild">
                <input
                  type="number"
                  placeholder="Etat de client"
                  v-model="ClientReleveCredit"
                  :disabled="PropsToClientPopup.Condition === 'Show'"
                />
              </div>
            </div>

            <div class="InputZone">
              <h6>Etat de client (actuel)</h6>
              <div class="InputFaild">
                <input
                  type="text"
                  placeholder="Etat de client actuel"
                  v-model="ClientSumOffFPriceCurrent"
                  disabled
                />
              </div>
            </div>

        </div>
      </div>
    </div>
    <div class="BtnPopUp">
      <v-progress-circular
        :size="70"
        :width="7"
        color="purple"
        indeterminate
        v-if="ActiveSpinner"
      ></v-progress-circular>
      <button
        v-if="PropsToClientPopup.Condition == 'New' && !ActiveSpinner"
        @click="AddNewClient()"
      >
        Valider
      </button>

      <button
        v-else-if="PropsToClientPopup.Condition == 'Update' && !ActiveSpinner"
        @click="UpdateClient()"
      >
        Mettre à jour
      </button>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import Vue from "vue";
import axios from "axios";
import API_URL from "../../config";
import SOCKETS_URL from "../../configSocketsURL";
import io from "socket.io-client";

export default {
  emit: ["Close"],
  props: ["PropsToClientPopup"],
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      Submit: false,
      NewTCP: false,
      AllTCP: [],
      TCPchoose: "Select un TCP",
      Personne: "Morale",
      CodeCliet: "",
      RaisonSociel: "",
      ICE: "",
      Téléphone: "",
      Mail: "",
      Responsable: "",
      Responsable_Téléphone: "",
      Adresse: "",
      
      CodeComptable: "",
      CodeProduitComptable: "",
      sockets: io.connect(`${SOCKETS_URL}`),

      Plaphon_one: "",
      Plaphon_two: "",

      Deadline1: "",
      Deadline2: "",

      ClientSumOffFPrice: "",
      ClientSumOffFPriceCurrent: "",

      ClientReleveCredit: "",

      ActiveSpinner: false

      // PrénomAndNom: "",
      // CIN: "",
    };
  },
  created() {},
  computed: {},
  methods: {
    GetConditionProps() {
      console.log(this.PropsToClientPopup);
    },
    AddNewClient() {
      this.ActiveSpinner = true
      this.Submit = true;

      if(this.TCPchoose == "" || this.TCPchoose == "Select un TCP") {
          this.ActiveSpinner = false
          return Vue.$toast.open({
            message: "Selectionner une TCP",
            type: "error",
          });
      }
      if(this.Mail != "" && !(/^\w+([\.-]?\w+)+@\w+(\.\w{2,3})+$/.test(this.Mail))) {
        this.ActiveSpinner = false
          return Vue.$toast.open({
            message: "Entrer une adress email correct",
            type: "error",
          });
      }
      if(this.RaisonSociel == "") {
          this.ActiveSpinner = false
          return Vue.$toast.open({
            message: this.Personne === "Morale" ? "Entrer votre Raison sociale" : "Entrer votre nom & prénom",
            type: "error",
          });
      }
      if(this.CodeCliet == "") {
          this.ActiveSpinner = false
          return Vue.$toast.open({
            message: "Entrer votre code client",
            type: "error",
          });
      }
      if(this.ICE == "") {
          this.ActiveSpinner = false
          return Vue.$toast.open({
            message: this.Personne === "Morale" ? "Entrer votre code ICE" : "Entrer votre CIN",
            type: "error",
          });
      }

        let formData = {
          Role: this.Personne === "Morale" ? "M" : "F",
          ClientUCodeFor: this.TCPchoose,
          ClientCode: this.CodeCliet, 
          Name: this.RaisonSociel,
          Phone: this.Téléphone, 
          ICE: this.ICE, 
          Mail: this.Mail, 
          Responsable: this.Responsable,
          ResponsablePhone: this.Responsable_Téléphone, 
          Adress: this.Adresse, 
          CodeComptable: this.CodeComptable,

          Plaphon1: this.Plaphon_one,
          Plaphon2: this.Plaphon_two,
          Deadline1: this.Deadline1,
          Deadline2: this.Deadline2,
          ClientSumOffFPrice: 0,
          ClientReleveCredit: this.ClientReleveCredit
        };
        axios
          .post(`${API_URL}/codeClient/addCodeClient`, formData)
          .then((response) => {
            this.ActiveSpinner = false
            console.log(response);
            this.$emit("Close");
            Vue.$toast.open({
              message: "Validé",
              type: "success",
            });
          })
          .catch((err) => {
            this.ActiveSpinner = false
            console.log(err.response.data);
            Vue.$toast.open({
              message: err.response.data.error,
              type: "error",
            });
          });
    },
    UpdateClient() {
      this.ActiveSpinner = true
      this.Submit = true;

      if(this.TCPchoose == "" || this.TCPchoose == "Select un TCP") {
        this.ActiveSpinner = false
          return Vue.$toast.open({
            message: "Selectionner une TCP",
            type: "error",
          });
      }
      // if(this.Mail == "" || !(/^\w+([\.-]?\w+)+@\w+(\.\w{2,3})+$/.test(this.Mail))) {
      //     return Vue.$toast.open({
      //       message: "Entrer une adress email correct",
      //       type: "error",
      //     });
      // }
      if(this.RaisonSociel == "") {
          this.ActiveSpinner = false
          return Vue.$toast.open({
            message: this.Personne === "Morale" ? "Entrer votre Raison sociale" : "Entrer votre nom & prénom",
            type: "error",
          });
      }
      if(this.CodeCliet == "") {
          this.ActiveSpinner = false
          return Vue.$toast.open({
            message: "Entrer votre code client",
            type: "error",
          });
      }

          let formData = {
            Role: this.Personne === "Morale" ? "M" : "F",
            ClientUCodeFor: this.TCPchoose,
            ClientCode: this.CodeCliet, 
            Name: this.RaisonSociel,
            Phone: this.Téléphone, 
            ICE: this.ICE, 
            Mail: this.Mail, 
            Responsable: this.Responsable,
            ResponsablePhone: this.Responsable_Téléphone, 
            Adress: this.Adresse, 
            CodeComptable: this.CodeComptable,

            Plaphon1: this.Plaphon_one,
            Plaphon2: this.Plaphon_two,
            Deadline1: this.Deadline1,
            Deadline2: this.Deadline2,
            ClientSumOffFPrice: this.ClientSumOffFPrice,

            ClientReleveCredit: this.ClientReleveCredit
          };

          let ObjectSendit = {
            CodeComptable: this.CodeComptable,
          };

          axios.all(
            [
              axios.put(`${API_URL}/codeClient/WEB/updateOneClientDetails/${this.PropsToClientPopup.Id}`, formData),
              axios.put(`${API_URL}/rapport/getAndUpdateRapportHaveThisClientComptable/${this.CodeCliet}`, ObjectSendit),
            ]
          )
          .then(axios.spread((response1, response2) => {
            if(response1.data) {
              Vue.$toast.open({
                message: "Les informations du client ont été modifiées.",
                type: "success",
              });
            }
            if(response2.data) {
                this.ActiveSpinner = false
                this.sockets.emit("ModifyClientStatus");

                Vue.$toast.open({
                  message: `le code comptable a été modifiée sur ${response2.data.nModified} rapport`,
                  type: "success",
                });

                axios.put(`${API_URL}/rapport/addCliendIdToRapportReq/${this.CodeCliet}`)
                .then(res => {
                  if(res.data) {
                    this.ActiveSpinner = false
                    Vue.$toast.open({
                      message: `donneé de client a été ajoutée sur ${res.data.nModified} rapport`,
                      type: "success",
                    });
                  }
                })
                .catch(err => {
                  this.ActiveSpinner = false
                  return Vue.$toast.open({
                    message: err.response.data.error,
                    type: "error",
                  });
                });
            }

            this.$emit('Close');
          }))
          .catch(err => {
            this.ActiveSpinner = false
            return Vue.$toast.open({
              message: err.response.data.error,
              type: "error",
            });
          });
    },
    GetAllTpc() {
      axios.get(`${API_URL}/codeClient/getAllTCP`).then((response) => {
        response.data.forEach((element) => {
          this.AllTCP.push({ Name: element.Name, Id: element._id });
        });
        console.log(response);
      });
    },
    GetClientData() {
      if (this.PropsToClientPopup.Condition !== "New") {
        axios
          .get(
            `${API_URL}/codeClient/WEB/OneClientDetails/${this.PropsToClientPopup.Id}`
          )
          .then((response) => {
            console.log(response.data);
            console.log("TCA == ", response.data[0].ClientUCodeFor.Name);
            this.TCPchoose = response.data[0].ClientUCodeFor._id;
            response.data[0].Role == "M"
              ? (this.Personne = "Morale")
              : (this.Personne = "Physique");
            this.CodeCliet = response.data[0].ClientCode;
            this.RaisonSociel = response.data[0].Name;
            this.Téléphone = response.data[0].Phone;
            this.ICE = response.data[0].ICE;
            this.Mail = response.data[0].Mail;
            this.Responsable = response.data[0].Responsable;
            this.Responsable_Téléphone = response.data[0].ResponsablePhone;
            this.Adresse = response.data[0].Adress;
            this.PrénomAndNom = response.data[0].Name;
            this.CIN = response.data[0].ICE;
            this.CodeComptable = response.data[0].CodeComptable;

            this.Plaphon_one = response.data[0].Plaphon1;
            this.Plaphon_two = response.data[0].Plaphon2;
            this.Deadline1 = response.data[0].Deadline1;
            this.Deadline2 = response.data[0].Deadline2;
            this.ClientSumOffFPrice = response.data[0].ClientSumOffFPrice;
            this.ClientSumOffFPriceCurrent = response.data[0].ClientSumOffFPriceCurrent;
            this.ClientReleveCredit = response.data[0].ClientReleveCredit
          });
      }
    },
  },
  mounted() {
    this.GetConditionProps();
    this.GetAllTpc();
    this.GetClientData();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.PopupNewClient {
  position: fixed;
  width: 40%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 30%;
  top: 10%;
  z-index: 100000;
  padding: 40px 40px 100px;
  overflow-y: scroll;
  height: 80%;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  button {
    font-size: 17px;
    font-family: $primaryFont;
    font-weight: 700;
    border-radius: 10px;
    padding: 6px 30px;
    background-color: $primary-background-color;
    color: white;
    &:hover {
      border: 1px solid $primary-background-color;
      background-color: transparent;
      color: $primary-background-color;
    }
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }
      .InputFaild {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  .TCP {
    margin-bottom: 20px;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }

    select {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .Personne {
    display: flex;
    align-items: center;
    p {
      margin-right: 50px;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048 !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #142048;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1840px) {
  }
  @media screen and (max-width: 1640px) {
    padding: 20px 40px !important;
    width: 60%;
    top: 2%;
    left: 20%;
  }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px;
      padding-bottom: 10px !important;
    }
    h6 {
      font-size: 15px !important;
    }
    p {
      font-size: 15px !important;
    }
    input,
    select,
    textarea {
      padding: 3px !important;
    }
    .InputZone {
      padding: 3px;
      margin: 10px 0px !important;
    }
  }
}
</style>
