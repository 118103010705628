<template>
  <div>
    <div class="BalckPage" v-if="OpenClient || OpenReleve" @click="RemoveAllPopup"></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <PopupClinet
      v-if="OpenClient"
      :PropsToClientPopup="PropsToClientPopup"
      @Close="CloseFuntion()"
    />
    <PopupReleve v-if="OpenReleve" :PropsToRelevePopup="PropsToRelevePopup" @Close="CloseFuntion()" />

    <div class="ListClient">
      <div class="OuClientAndBtn">
        <h1>Nos Clients</h1>
        <button
          @click="(PropsToClientPopup.Condition = 'New'), (OpenClient = true)"
        >
          Nouveau client
        </button>

        <button
          @click="handleExelDownload"
        >
          Télécharger xcel
        </button>
      </div>
      <div class="Filter">
        <div class="FilterParCatég">
          <h6>Filtrer par TCP :</h6>
          <select v-model="TCPFilterd" @change="filtredCodeClient()">
            <option value="Tout">Tout</option>
            <option v-for="(TCP, n) in AllTCP" :key="n" :value="TCP.Id">
              {{ TCP.Name }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par client :</h6>
          <input
            type="text"
            placeholder="Tout"
            v-model="ClientFilterd"
            @keyup="filtredCodeClient"
          />
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par ICE / CIN :</h6>
          <input
            type="text"
            placeholder="Tout"
            v-model="ICEFiltred"
            @keyup="filtredCodeClient"
            :disabled="this.emptyCINorICE"
          />
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer code client :</h6>
          <input
            type="text"
            placeholder="Tout"
            v-model="ClientCode"
            @keyup="filtredCodeClient"
          />
        </div>
        <div class="FilterParCatég">
          <h6>vide ICE/CIN :</h6>
          <v-switch
            v-model="emptyCINorICE"
            @click="filtredCodeClient"
          ></v-switch>
        </div>
      </div>
      <div class="HeaderTable">
        <h3>TCP</h3>
        <h3>Client</h3>
        <h3>Code</h3>
        <h3>Téléphone</h3>
        <h3>ICE/CIN</h3>
        <h3>Actions</h3>
        <h3>Activer/Désactiver</h3>
        <h3>Relevé</h3>
      </div>

      <div class="d-flex justify-center ma-10" v-if="!clientData">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>

      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>
      <div class="TableContentZone">
        <div
          class="HeaderBody"
          v-for="(Client, n) in AllClientHave"
          :key="n"
          :style="
            n % 2 == 0
              ? 'background-color: rgba(5,139,132,0.22)'
              : 'background-color: rgba(180,181,181,0.22);'
          "
        >
          <h3>{{ Client.ClientUCodeFor ? Client.ClientUCodeFor.Name : "" }}</h3>
          <h3>{{ Client.Name }}</h3>
          <h3>{{ Client.ClientCode }}</h3>
          <h3>{{ Client.Phone }}</h3>
          <h3>{{ Client.ICE }}</h3>
          <h3>
            <svg
              @click="
                (PropsToClientPopup.Condition = 'Show'),
                  (PropsToClientPopup.Id = Client._id),
                  (OpenClient = true)
              "
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"
              />
            </svg>
            <svg
              @click="
                (PropsToClientPopup.Condition = 'Update'),
                  (PropsToClientPopup.Id = Client._id),
                  (OpenClient = true)
              "
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
              />
            </svg>
            <svg
              @click="DeleteFunction(Client._id)"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
              />
            </svg>
          </h3>
          <h3 style="display: flex; justify-content: center">
            <v-switch
              v-model="Client.IsActive"
              @click="updateUserStatus(Client._id, Client.ClientCode)"
            ></v-switch>
          </h3>
          <h3>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 293.142 293.142"
              style="enable-background: new 0 0 293.142 293.142"
              xml:space="preserve"
              @click="(PropsToRelevePopup.ClientCode = Client.ClientCode), (PropsToRelevePopup.Id = Client._id), (OpenReleve = true)"
            >
              <g>
                <g>
                  <polygon
                    style="fill: #f9ba48"
                    points="132.508,79.688 141.883,220.313 113.758,220.313 170.008,285.938 226.258,220.313 
			198.133,220.313 207.508,79.688 		"
                  />
                  <path
                    style="fill: #333333"
                    d="M245.008,42.188V0H1.258v215.625c0,15.511,12.614,28.125,28.125,28.125h98.292l42.333,49.392
			l42.333-49.392h51.417c15.511,0,28.125-12.614,28.125-28.125V42.188H245.008z M10.633,215.625V9.375h225v32.813H130.717
			l-9.375-18.75H48.133v192.188c0,10.341-8.409,18.75-18.75,18.75S10.633,225.966,10.633,215.625z M282.509,215.625
			c0,10.341-8.409,18.75-18.75,18.75H220.38l16.073-18.75h-33.305l9.042-135.628l-9.352-0.623L193.122,225h22.945l-46.059,53.733
			L123.948,225h22.945l-9.708-145.622l-9.352,0.623l9.037,135.623h-33.305l16.073,18.75H50.289
			c4.472-4.983,7.219-11.541,7.219-18.75V32.813h58.041l9.375,18.75h157.584V215.625z"
                  />
                </g>
              </g>
            </svg>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import PopupClinet from "../components/PopupNewClient.vue";
import PopupReleve from "../components/ListClientPopup/Releve.vue"
import Swal from "sweetalert2";
import Vue from "vue";

import axios from "axios";
import API_URL from "../../config";

import SOCKETS_URL from "../../configSocketsURL";
import io from "socket.io-client";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    PopupClinet,
    PopupReleve
  },
  data() {
    return {
      OpenClient: false,
      OpenReleve: false,
      PropsToClientPopup: {
        Condition: "",
        Id: "",
      },
      PropsToRelevePopup: {
        ClientName: "",
        Id: "",
      },
      AllClientHave: [],
      AllTCP: [],
      TCPFilterd: "Tout",
      ClientFilterd: "",
      ICEFiltred: "",
      CINFiltred: "",
      VideData: false,

      client_switch: false,

      emptyCINorICE: false,

      ClientCode: "",

      sockets: io.connect(`${SOCKETS_URL}`),

      clientData: false
    };
  },
  created() {
    // getAllRecouvremmentDataAfterUpdateClientStatus
    // this.sockets.on("getAllRecouvremmentDataAfterUpdateClientStatus", () => {
    //   this.FunctionToGetAllRapportVisiteDeRecouvrement();
    // });
  },
  computed: {},
  methods: {
    handleExelDownload() {
      this.clientData = false
      axios
        .get(`${API_URL}/codeClient/filtredCodeClientXcel?Name=${this.ClientFilterd}&ICE=${this.ICEFiltred}&ClientCode=${this.ClientCode}&ClientUCodeFor=${this.TCPFilterd}&emptyCINorICE=${this.emptyCINorICE}`)
        .then(response => {
          this.clientData = true
          window.open(`${SOCKETS_URL}/${response.data}`)
        })
    },
    filtredCodeClient() {
      this.clientData = false
      axios
        .get(`${API_URL}/codeClient/filtredCodeClient?Name=${this.ClientFilterd}&ICE=${this.ICEFiltred}&ClientCode=${this.ClientCode}&ClientUCodeFor=${this.TCPFilterd}&emptyCINorICE=${this.emptyCINorICE}`)
        .then((response) => {
          this.AllClientHave = response.data;
          if (this.AllClientHave.length == 0)
            this.VideData = true;
          else
            this.VideData = false;

          this.clientData = true
        });
    },
    updateUserStatus(userCodeID, ClientCode) {
      axios
        .put(`${API_URL}/codeClient/WEB/updateClientStatus/${userCodeID}/${ClientCode}`)
        .then((response) => {
          Vue.$toast.open({
            message: response.data == true ? "activée" : "disactivée",
            type: "success",
          });

          let ObjectSendit = {
            EnableDiableClient: response.data,
          };
          axios
            .put(
              `${API_URL}/rapport/getAndUpdateRapportHaveThisClient/${ClientCode}`,
              ObjectSendit
            )
            .then((responsetwo) => {
              Vue.$toast.open({
                message:
                  response.data == true
                    ? `activé sur ${responsetwo.data.nModified} rapport`
                    : `disactivé sur ${responsetwo.data.nModified} rapport`,
                type: "success",
              });

              this.sockets.emit("ModifyClientStatus");
            });
        });
    },
    RemoveAllPopup() {
      this.OpenClient = false;
      this.OpenReleve = false;
    },
    GetAllClient() {
      axios
        .get(`${API_URL}/codeClient/WEB/filtredByName?NAME`)
        .then((response) => {
          this.AllClientHave = response.data;
        });
    },
    DeleteFunction(ID) {
      Swal.fire({
        position: "center-center",
        text: "Voulez-vous supprimer ce client?",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`${API_URL}/codeClient/WEB/deleteOneClientDetails/${ID}`)
            .then((response) => {
              this.GetAllClient();
            });
        }
      });
    },
    GetAllElementFiltred() {
      axios.get(`${API_URL}/codeClient/getAllTCP`).then((response) => {
        response.data.forEach((element) => {
          this.AllTCP.push({ Name: element.Name, Id: element._id });
        });
      });
    },
    CloseFuntion() {
      this.OpenClient = false;
      this.OpenReleve = false;
      // this.GetAllClient();
      this.filtredCodeClient()
    },
  },
  mounted() {
    // this.GetAllClient();
    this.filtredCodeClient()
    this.GetAllElementFiltred()
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00abc4;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 4%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0px auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>

