<template>
  <div>
    <div class="RapportDeVente">
            <h2>Rapport</h2>
            <h1>Visite essay</h1>
            <div>
                <div>
                    <h2 style="margin:30px 0px;"></h2>
                    <table>
                        <tr>
                            <th>Product</th>
                            <th>Date recolte</th>
                            <th>TCP</th>
                            <th>Description</th>
                        </tr>
                        <tr>
                            <td>{{ VeData.product }}</td>
                            <td>{{ VeData.dateRecolt }}</td>
                            <td>{{ VeData.tcp }}</td>
                            <td>{{ VeData.description }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="DownlodPdf">
                <button @click="downloadVePdf">Telecharger PDF</button>
            </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import axios from "axios";
import API_URL from "./../../../config";

export default {
 props:["RapportId"],
  data() {
    return {
        Id:'',
        ForId:'',
        ModeOperation:'',
        VeData: {
            product: "",
            dateRecolt: "",
            tcp: "",
            description: ""
        }
    };
  },
  computed: {},
  methods: {
    downloadVePdf() {
        window.open(`${API_URL}/PDF/DownloadRapportFromBackVe/${this.RapportId}/for/typeOf/VisiteEssay`, '_blank')
    },
    GetRapportData() {
        axios.get(`${API_URL}/rapport/getOne/${this.RapportId}`).then((response) => {
            this.Id = response.data._id,
            this.ForId = response.data.for._id
            this.ModeOperation = response.data.ModeOperation

            this.VeData.product = response.data.Rapport.InfoVisiteEssay.Variete
            this.VeData.dateRecolt = (response.data.Rapport.InfoVisiteEssay.dateDeRecolteEstimee).substr(0, 10).split('-').reverse().join("-")
            this.VeData.tcp = response.data.for.Name
            this.VeData.description = response.data.Rapport.InfoVisiteEssay.description
        });
    },
  },
  mounted(){
      this.GetRapportData()
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #058B84;
.RapportDeVente{
    height: 800px;
    overflow-y: scroll;
    width:50%;
    left: 30%;
    position: absolute;
    z-index: 100;
    background: linear-gradient(180deg, #f3f3f3 30%, #dfdfdf 100%);
    border-radius: 10px;
    box-shadow: 0px 0px 11px -4px;
    text-align: center;
    padding:20px;
    top: 5%;
    // @media (max-width: 1600px) {
    //     height: 500px;
    // }
    h2,h1{
        font-family:$primaryFont ;
        font-size: 25px;
        font-weight:600;
        color:$primary-background-color;
    }
    h1{
        font-size: 40px !important;
        font-weight: bold;
    }
    p{
        font-size: 25px !important;
        font-weight: 600;
        text-align: start;
        line-height: 30px !important;
    }
    .ZoneTable{
        margin-bottom: 40px;
        p {
            color: #058B84;
        }
    }
    table{
        width:100%;
        th{
            background-color:#058B84;
            font-size: 20px;
            color:white;
            padding:10px 0px;
        }
        td{
            font-size: 20px;
            color:#058B84;
            padding:10px 0px;
            border: 1px solid #058B84;
            font-weight: bold;
        }
    }
    .QRCODE{
        padding: 40px;
    }
    .DownlodPdf{
        button{
            padding:10px 40px;
            border-radius: 30px;
            font-weight: bold;
            background: linear-gradient(90deg, rgba(5,139,132,1) 30%, rgba(0,0,0,1) 100%);
            color: #FFFFFF;
            display:block !important;
            margin: 10px auto;
            &:hover{
                background: transparent;
                color:#00abc4;
                border:1px solid #00abc4;
            }

        }
    }
}
</style>

