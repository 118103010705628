<template>
  <div>
    <div class="BalckPage" v-if="OpenClient" @click="RemoveAllPopup"></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <PopupClinet
      v-if="OpenClient"
      :PropsToClientPopup="PropsToClientPopup"
      @Close="CloseFuntion()"
    />

    <div class="ListClient">
      <div class="OuClientAndBtn">
        <h1>Mouvement des produits</h1>
        <!-- <button @click="PropsToClientPopup.Condition='New',OpenClient = true">Nouveau client</button> -->
      </div>
      <div class="Filter" style="margin-bottom: 20px">
        <div class="FilterParCatég">
          <h6>Filtrer par catégorie :</h6>
          <select v-model="CatégoryFilterd" @change="FilterByCatégory()">
            <option value="Tout">Tout</option>
            <option v-for="(Catég, n) in Catégory" :key="n" :value="Catég">
              {{ Catég }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par produit :</h6>
          <input
            type="text"
            placeholder="Tout"
            v-model="ProduitFiltred"
            @keyup="FilterByProduit"
          />
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par TCP :</h6>
          <select v-model="TCPFilterd" @change="FiltredByTCP()">
            <option value="Tout">Tout</option>
            <option v-for="(TCP, n) in AllTCP" :key="n" :value="TCP.Name">
              {{ TCP.Name }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Sortie / Entrée :</h6>
          <select v-model="DepotEntreSorte" @change="FiltredByES()">
            <option value="Tout">Tout</option>
            <option value="E">Entrée</option>
            <option value="S">Sorté</option>
          </select>
        </div>
        <v-btn fab dark color="#1cb6b6" @click="ResetInput">
          <v-icon dark>mdi-autorenew</v-icon>
        </v-btn>
      </div>
      <div class="HeaderTable">
        <h3>Mode OP</h3>
        <h3>Produit</h3>
        <h3>Catégorie</h3>
        <h3>Dépôt</h3>
        <h3>Quantité</h3>
        <h3>Date OP</h3>
        <h3>Responsable OP</h3>
        <h3>Client</h3>
        <h3>Reste en stock</h3>
      </div>

      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>

      <div v-if="!dataIsLoaded">
        <div class="spinner">
          <v-progress-circular
            :size="100"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>

      <div class="TableContentZone" v-if="dataIsLoaded">
        <div v-for="(Depot, index) in AllClientHave" :key="index">
          <!-- <div class="HeaderBody" :style="Depot.Type == 'S' ? 'background-color: rgba(157,0,0,0.2)' : 'background-color: rgba(5,139,132,0.22)'"> -->
          <div
            class="HeaderBody"
            :style="
              Depot.Type == 'S' && Depot.MO != 'BL' && Depot.MO != 'BR'
                ? 'background-color: rgba(157,0,0,0.2)'
                : Depot.Type == 'E' && Depot.MO != 'BL' && Depot.MO != 'BR'
                ? 'background-color: rgba(5,139,132,0.22)'
                : Depot.MO == 'BL'
                ? 'background-color: rgba(255,0,0,0.22)'
                : Depot.MO == 'BR'
                ? 'background-color: rgba(255,237,65,0.22)'
                : ''
            "
          >
            <h3>{{ Depot.MO }}</h3>
            <h3>{{ Depot.produit }}</h3>
            <h3>{{ Depot.category }}</h3>
            <h3>
              {{
                Depot.selectedDepot
              }}
            </h3>
            <h3>
              {{
                Depot.selectedDepotDetails
                  ? Depot.selectedDepotDetails.quantity
                  : ""
              }}
            </h3>
            <h3>{{ Depot.dateOP }}</h3>
            <h3>{{ Depot.responsableOP }}</h3>
            <h3>{{ Depot.client }}</h3>
            <h3 v-if="Depot.MO == 'Stock' || Depot.MO == 'BL' || Depot.MO == 'BR'">
              {{
                  Depot.restEnStockForThisProduts
                  ? Depot.restEnStockForThisProduts.restOneStock
                  : Depot.restEnStock
              }}
            </h3>
            <h3 v-if="Depot.MO == 'transfer de stock'">
              {{
                Depot.restEnStock
              }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import PopupClinet from "../components/PopupNewClient.vue";
import Swal from "sweetalert2";

import axios from "axios";
import API_URL from "../../config";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    PopupClinet,
  },
  data() {
    return {
      OpenClient: false,
      PropsToClientPopup: {
        Condition: "",
        Id: "",
      },
      AllClientHave: [],
      AllDepot: [],
      DepotFilterd: "Tout",

      DepotBrBlTs: "Tout",
      ClientFilterd: "",
      ICEFiltred: "",
      CINFiltred: "",
      VideData: false,

      client_switch: false,

      emptyCINorICE: false,

      ClientCode: "",

      CatégoryFilterd: "Tout",
      Catégory: [],

      ProduitFiltred: "Tout",

      AllTCP: [],
      TCPFilterd: "Tout",

      DepotEntreSorte: "Tout",

      dataIsLoaded: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    ResetInput() {
      this.CatégoryFilterd = "Tout";
      this.TCPFilterd = "Tout";
      this.ProduitFiltred = "Tout";
      this.DepotEntreSorte = "Tout";

      this.GetAllClient();
    },
    FiltredByTCP() {
      this.GetAllClient();
    },
    GetAllElementFiltredTCP() {
      axios.get(`${API_URL}/codeClient/getAllTCP`).then((response) => {
        response.data.forEach((element) => {
          this.AllTCP.push({ Name: element.Name, Id: element._id });
        });
      });
    },
    GetAllCategoryHave() {
      axios
        .get(`${API_URL}/products/GetAllProductsCategory`)
        .then((response) => {
          this.Catégory = response.data;
        });
    },
    FilterByProduit() {
      // if(this.ProduitFiltred == '')
      //     this.ProduitFiltred = 'Tout'

      this.GetAllClient();
    },
    FilterByCatégory() {
      this.GetAllClient();
    },
    updateUserStatus(userCodeID) {
      axios
        .put(`${API_URL}/codeClient/WEB/updateClientStatus/${userCodeID}`)
        .then((response) => {
          console.log(response);
        });
    },
    RemoveAllPopup() {
      this.OpenClient = false;
    },
    GetAllClient() {
      this.dataIsLoaded = false;
      this.VideData = false;
      return axios
        .get(
          `${API_URL}/rapport/getAllFProductByDepot?category=${this.CatégoryFilterd}&produit=${this.ProduitFiltred}&responsableOP=${this.TCPFilterd}&Type=${this.DepotEntreSorte}`
        )
        .then((response) => {
          if (response.data.length == 0) this.VideData = true;

          this.dataIsLoaded = true;
          return (this.AllClientHave = response.data);
        });
    },
    DeleteFunction(ID) {
      Swal.fire({
        position: "center-center",
        text: "Voulez-vous supprimer ce client?",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Supprimer",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`${API_URL}/codeClient/WEB/deleteOneClientDetails/${ID}`)
            .then((response) => {
              this.GetAllClient();
            });
        }
      });
    },
    GetAllElementFiltred() {
      axios.get(`${API_URL}/country/getAllCity`).then((response) => {
        response.data.forEach((element) => {
          this.AllDepot.push(element);
        });
      });
    },
    FiltredByDepot() {
      this.GetAllClient();
    },
    FiltredByES() {
      this.GetAllClient();
    },
    FiltredByType() {
      this.GetAllClient().then((response) => {
        if (this.DepotBrBlTs == "Tout") this.AllClientHave = response;
        else {
          this.AllClientHave = response.filter(
            (el) => el.MO == this.DepotBrBlTs
          );
        }
      });
    },
    FilterByClient() {
      if (this.ClientFilterd != "") {
        axios
          .get(
            `${API_URL}/codeClient/WEB/filtredByName?NAME=${this.ClientFilterd}`
          )
          .then((response) => {
            this.AllClientHave = response.data;
            if (this.AllClientHave.length == 0) {
              this.VideData = true;
            } else {
              this.VideData = false;
            }
          });
      } else {
        this.VideData = false;
        this.GetAllClient();
      }
    },
    FilterByICE() {
      if (this.ICEFiltred != "") {
        axios
          .get(
            `${API_URL}/codeClient/WEB/filtredByCinOrIce/${this.ICEFiltred}?ROLE=M`
          )
          .then((response) => {
            this.AllClientHave = response.data;
            if (this.AllClientHave.length == 0) {
              this.VideData = true;
            } else {
              this.VideData = false;
            }
          });
      } else {
        this.VideData = false;
        this.GetAllClient();
      }
    },

    FilterByCodeClient() {
      console.log(this.ClientCode);
      if (this.ClientCode != "") {
        axios
          .get(
            `${API_URL}/codeClient/WEB/getClientCode?ClientCode=${this.ClientCode}`
          )
          .then((response) => {
            this.AllClientHave = response.data;
            if (this.AllClientHave.length == 0) {
              this.VideData = true;
            } else {
              this.VideData = false;
            }
          });
      } else {
        this.VideData = false;
        this.GetAllClient();
      }
    },

    FilterByCIN() {
      if (this.CINFiltred != "") {
        axios
          .get(
            `${API_URL}/codeClient/WEB/filtredByCinOrIce/${this.CINFiltred}?ROLE=P`
          )
          .then((response) => {
            this.AllClientHave = response.data;
            if (this.AllClientHave.length == 0) {
              this.VideData = true;
            } else {
              this.VideData = false;
            }
          });
      } else {
        this.VideData = false;
        this.GetAllClient();
      }
    },
    FilterByEmptyIceOrCin() {
      if (this.emptyCINorICE) {
        axios.get(`${API_URL}/codeClient/getEmptyICEorCIN`).then((response) => {
          this.AllClientHave = response.data;
          if (this.AllClientHave.length == 0) {
            this.VideData = true;
          } else {
            this.VideData = false;
          }
        });
      } else {
        this.GetAllClient();
      }
    },
    CloseFuntion() {
      this.OpenClient = false;
      this.GetAllClient();
    },
  },
  mounted() {
    this.GetAllClient();
    this.GetAllElementFiltred();
    this.GetAllCategoryHave();
    this.GetAllElementFiltredTCP();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00abc4;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.paragrapheHeigth {
  height: 40px;
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 4%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0px auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>
