var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tableContentsProducts"},[_c('div',{staticClass:"Filter"},[_c('div',{staticClass:"FilterParCatég"},[_c('h6',[_vm._v("Filtrer par Produit :")]),_c('v-autocomplete',{attrs:{"items":_vm.listProductsItems},on:{"change":_vm.filtredProductsFunc},model:{value:(_vm.ProductsFilterd),callback:function ($$v) {_vm.ProductsFilterd=$$v},expression:"ProductsFilterd"}})],1),_c('div',{staticClass:"FilterParCatég"},[_c('h6',[_vm._v("Filtrer par date :")]),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"color":"white"},attrs:{"color":"#1cb6b6"}},'v-btn',attrs,false),on),[_vm._v(" Choisir une Période ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h2 pa-12"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.pickerDateOne),callback:function ($$v) {_vm.pickerDateOne=$$v},expression:"pickerDateOne"}}),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"#1cb6b6"},on:{"click":_vm.getTableDetailsBySelectedDate}},[_vm._v("valider")])],1)],1)])],1)]}}])})],1)]),_c('div',{staticClass:"Filter"},[_c('div',{staticClass:"FilterParCatég"},[(!_vm.tcpTableEcSpinner)?_c('v-btn',{attrs:{"color":"success","fab":"","small":"","dark":""},on:{"click":_vm.downloadExcelFile}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()],1)]),_c('table',[_vm._m(0),_c('tbody',[(_vm.tcpTableEcSpinner)?[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('v-progress-circular',{attrs:{"color":"dark-blue","indeterminate":"","size":90}})],1)])]:_vm._e(),(
              _vm.tcpFiltredDataEC.tcpTableData &&
              _vm.tcpFiltredDataEC.tcpTableData.data &&
              !_vm.tcpTableEcSpinner
            )?[_vm._l((_vm.tcpFiltredDataEC.tcpTableData.data),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(value[0].Tcp))]),_c('td',[_vm._v(_vm._s(value[value.length - 1].sumOfQuantity))]),_c('td',[_vm._v(_vm._s(value[value.length - 1].sumOfAmount.toFixed(2)))])])}),_c('tr',{staticClass:"finalCategoryRow"},[_c('td'),_c('td',[_vm._v(_vm._s(_vm.tcpFiltredDataEC.tcpTableData.sumOfQuantity))]),_c('td',[_vm._v(_vm._s((_vm.tcpFiltredDataEC.tcpTableData.sumOfAmount).toFixed(2)))])])]:_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("TCP")]),_c('th',[_vm._v("Qty")]),_c('th',[_vm._v("Montant")])])])}]

export { render, staticRenderFns }