<template>
  <div class="PopupNewClient pdfRapportDetails">
    <h4 v-if="PropsToRapportPopup.Condition == 'Update'">Modifier un rapport</h4>
    <form>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="rapportGeneralInfo.InfoGenerale.NomSociete"
              label="Nom de la société"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="rapportGeneralInfo.InfoGenerale.Tel"
              label="Telephone"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="rapportGeneralInfo.InfoGenerale.ICE"
              label="ICE"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="rapportGeneralInfo.InfoGenerale.Adress"
              label="Adress"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="rapportGeneralInfo.InfoGenerale.Email"
              label="Email"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="rapportGeneralInfo.InfoGenerale.ClientCode"
              label="Code client"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="rapportGeneralInfo.FactureBL.factureBLValue"
              label="N° BL sur la facture"
              :disabled="!rapportGeneralInfo.FactureBL.modifiedFactureBL"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-switch
              v-model="rapportGeneralInfo.FactureBL.modifiedFactureBL"
              flat
              label="activer la modification de N° BL sur la facture"
            ></v-switch>
          </v-col>
        </v-row>
        <v-btn block @click="updateGeneralInfo"> Mettre à jour </v-btn>
      </v-container>
    </form>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import Vue from "vue";
import axios from "axios";
import API_URL from "@/../config";

export default {
  emit: ["Close"],
  props: ["PropsToRapportPopup"],
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      rapportGeneralInfo: {
        InfoGenerale: {
          NomSociete: "",
          Tel: "",
          ICE: "",
          Adress: "",
          Email: "",
          ClientCode: "",
        },
        FactureBL: {
          modifiedFactureBL: false,
          factureBLValue: ""
        }
      },
    };
  },
  computed: {},
  methods: {
    getRapportGeneralInfo() {
      axios
        .get(
          `${API_URL}/rapport/getRapportGeneralInfo/${this.PropsToRapportPopup.Id}`
        )
        .then((rapport) => {
          this.rapportGeneralInfo = rapport.data;
        });
    },
    updateGeneralInfo() {
      axios
        .put(
          `${API_URL}/rapport/updateRapportGeneralInfo/${this.PropsToRapportPopup.Id}`,
          this.rapportGeneralInfo
        )
        .then(() => {
          this.$emit("Close");
          Vue.$toast.open({
            message: "Les informations du rapport ont été modifiées.",
            type: "success",
          });
        });
    },
  },
  mounted() {
    this.getRapportGeneralInfo();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.pdfRapportDetails {
}

.PopupNewClient {
  position: absolute;
  width: 40%;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 30%;
  top: 10%;
  z-index: 100000;
  overflow: auto;
  height: 800px;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }
      .InputFaild {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  .TCP {
    margin-bottom: 20px;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }

    select {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .Personne {
    display: flex;
    align-items: center;
    p {
      margin-right: 50px;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048 !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #142048;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1840px) {
  }
  @media screen and (max-width: 1640px) {
    padding: 20px 40px !important;
    width: 60%;
    top: 2%;
    left: 20%;
  }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px;
      padding-bottom: 10px !important;
    }
    h6 {
      font-size: 15px !important;
    }
    p {
      font-size: 15px !important;
    }
    input,
    select,
    textarea {
      padding: 3px !important;
    }
    .InputZone {
      padding: 3px;
      margin: 10px 0px !important;
    }
  }
}
</style>
