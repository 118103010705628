<template>
  <div class="PopupNewClient">
    <h4 v-if="PropsToProductPopup.Condition == 'New'">Nouveau</h4>
    <h4 v-else-if="PropsToProductPopup.Condition == 'Update'">Mettre à jour</h4>
    <h4 v-else-if="PropsToProductPopup.Condition == 'Show'">Aperçu</h4>

    <!-- <div class="TCP">
                    <div class="InputZone">
                            <h6>Produit</h6>
                                <select v-model="CategoryChose" 
                                :disabled="PropsToProductPopup.Condition == 'Show'"
                                 :style="CategoryChose === 'Séléctioner une produit'
                                        && SubmitForm === true  
                                        && NewCatégory === ''
                                        ? 'border: 2px solid red' : ''"
                                >
                                    <option value="Séléctioner une produit">Séléctioner une produit</option>
                                    <option  v-for="Categ,n in Cattegory" :key="n" :value="Categ">{{Categ}}</option>
                                </select>
                    </div>
            </div> -->
    <div class="NewCateg" v-if="AddNewCatég">
      <input
        type="text"
        Placeholder="Nouvelle Catégorie"
        v-model="NewCatégory"
      />
    </div>

    <!-- add new achat national -->
    <!-- 
      PropsToProductPopup.Condition == 'Show'
      PropsToProductPopup.Condition == 'New'
      PropsToProductPopup.Condition == 'Update'
    -->
  <div v-if="PropsToProductPopup.Condition == 'New'">
    <div class="Form" v-for="(nationalSell, index) in nationalSellForm" :key="index" >
      <div class="LeftInputs">
        <div class="InputZone">
          <h6>Produit</h6>
          <select
            v-model="nationalSellForm[index].product"
          >
            <option value="Séléctioner une produit">
              Séléctioner une produit
            </option>
            <option v-for="(Categ, n) in Cattegory" :key="n" :value="Categ">
              {{ Categ }}
            </option>
          </select>
        </div>
        <div class="InputZone">
          <h6>Prix HT : en DH</h6>
          <div
            class="InputFaild"
            :style="
              PriceHT === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              v-model="nationalSellForm[index].htPrice"
              @keyup="CalculePrixTTC()"
              @change="CalculePrixTTC()"
            />
          </div>
        </div>
        <div class="InputZone">
          <h6>Date d’expiration</h6>
          <div
            class="InputFaild"
            :style="
              ExperationDate === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="date"
              v-model="nationalSellForm[index].experation"
              @keyup="CalculePrixTTC()"
              @change="CalculePrixTTC()"
            />
          </div>
        </div>
      </div>
      <div class="RightInputs">
        <div class="InputZone">
          <h6>Quantité acheté</h6>
          <div
            class="InputFaild"
            :style="
              CodeProduit === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              placeholder="Quantité acheté"
              v-model="nationalSellForm[index].sellQuantity"
            />
          </div>
        </div>
        <div class="InputZone">
          <h6>Quantité d'offre</h6>
          <div
            class="InputFaild"
            :style="
              Produit === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              placeholder="Quantité d'offre"
              v-model="nationalSellForm[index].sellOffer"
            />
          </div>
        </div>
        <div class="InputZone">
          <h6>Numéro de lot</h6>
          <div
            class="InputFaild"
            :style="
              LotNumber === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              placeholder="Numéro de lot"
              v-model="nationalSellForm[index].batchNumber"
            />
          </div>
        </div>
      </div>

      <svg class="deleteAchat" v-if="(PropsToProductPopup.Condition == 'New' || PropsToProductPopup.Condition == 'Update') && nationalSellForm.length != 1" @click="deleteOneNationalSellForm(index)" xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 0 24 24" width="100px" fill="red"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>

    </div>

    <button @click="addNewNationalSellForm">Ajouter</button>

    <div class="BtnPopUp">
      <button
        @click="AddNewProduct()"
      >
        Valider
      </button>
    </div>
  </div>

    <!-- update achat national -->
    <div v-if="PropsToProductPopup.Condition == 'Update' || PropsToProductPopup.Condition == 'Show'">
    <div class="Form" v-for="(nationalSell, index) in nationalSellForm" :key="index" >
      <div class="LeftInputs">
        <div class="InputZone">
          <h6>Produit</h6>
          <select
            v-model="nationalSellForm[index].product"
            :disabled="PropsToProductPopup.Condition == 'Show'"
          >
            <option value="Séléctioner une produit">
              Séléctioner une produit
            </option>
            <option v-for="(Categ, n) in Cattegory" :key="n" :value="Categ">
              {{ Categ }}
            </option>
          </select>
        </div>
        <div class="InputZone">
          <h6>Prix HT : en DH</h6>
          <div
            class="InputFaild"
            :style="
              PriceHT === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              v-model="nationalSellForm[index].htPrice"
              :disabled="PropsToProductPopup.Condition == 'Show'"
              @keyup="CalculePrixTTC()"
              @change="CalculePrixTTC()"
            />
          </div>
        </div>
        <div class="InputZone">
          <h6>Date d’expiration</h6>
          <div
            class="InputFaild"
            :style="
              ExperationDate === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="date"
              v-model="nationalSellForm[index].experation"
              :disabled="PropsToProductPopup.Condition == 'Show'"
              @keyup="CalculePrixTTC()"
              @change="CalculePrixTTC()"
            />
          </div>
        </div>
      </div>
      <div class="RightInputs">
        <div class="InputZone">
          <h6>Quantité acheté</h6>
          <div
            class="InputFaild"
            :style="
              CodeProduit === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              placeholder="Quantité acheté"
              v-model="nationalSellForm[index].sellQuantity"
              :disabled="PropsToProductPopup.Condition == 'Show'"
            />
          </div>
        </div>
        <div class="InputZone">
          <h6>Quantité d'offre</h6>
          <div
            class="InputFaild"
            :style="
              Produit === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              placeholder="Quantité d'offre"
              v-model="nationalSellForm[index].sellOffer"
              :disabled="PropsToProductPopup.Condition == 'Show'"
            />
          </div>
        </div>
        <div class="InputZone">
          <h6>Numéro de lot</h6>
          <div
            class="InputFaild"
            :style="
              LotNumber === '' && SubmitForm === true
                ? 'border: 2px solid red'
                : ''
            "
          >
            <input
              type="number"
              placeholder="Numéro de lot"
              v-model="nationalSellForm[index].batchNumber"
              :disabled="PropsToProductPopup.Condition == 'Show'"
            />
          </div>
        </div>
      </div>

      <svg class="deleteAchat" v-if="(PropsToProductPopup.Condition == 'Update') && nationalSellForm.length != 1" @click="deleteOneNationalSellForm(index)" xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 0 24 24" width="100px" fill="red"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>

    </div>

    <button @click="addNewNationalSellForm" v-if="PropsToProductPopup.Condition == 'Update'">Ajouter</button>

    <div class="BtnPopUp">
      <button
        v-if="PropsToProductPopup.Condition == 'Update'"
        @click="UpdateProduct()"
      >
        Mettre à jour
      </button>
    </div>
  </div>

  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

import axios from "axios";
import API_URL from "../../../config";
import Vue from "vue";

export default {
  emit: ["close"],
  props: ["PropsToProductPopup"],
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      SubmitForm: false,
      NewTCP: false,
      AddNewCatég: false,
      PriceTTC: 0,
      AutreUnité: false,
      NewCatégory: "",

      Unité: ["KG", "L", "Unit"],
      UnitéChose: "Select un unité",
      NewUnité: "",

      Tva: 0,
      Remise: 0,
      Emballage: 0,
      NewDepot: {
        Name: "",
      },
      NewDepotOpen: false,
      Dépot: [],

    Cattegory: [],

    nationalSellForm: [
        {
            product: "Séléctioner une produit",
            htPrice: 0,
            experation: "",
            sellQuantity: 0,
            sellOffer: "",
            batchNumber: "",
        },
    ]

    };
  },
  created() {},
  computed: {},
  methods: {
    addNewNationalSellForm() {
      this.nationalSellForm.push({
            product: "Séléctioner une produit",
            htPrice: 0,
            experation: "",
            sellQuantity: 0,
            sellOffer: 0,
            batchNumber: 0,
      })
    },
    deleteOneNationalSellForm(index) {
      this.nationalSellForm.splice(index, 1)
    },
    GetConditionProps() {
      console.log(this.PropsToProductPopup);
    },
    CalculePrixTTC() {
      if (this.PriceHT !== "" && this.PriceHT !== 0) {
        let PrixWithoutRemise =
          parseFloat(this.PriceHT) +
          parseFloat(this.PriceHT * (this.Tva / 100));
        this.PriceTTC = parseFloat(PrixWithoutRemise).toFixed(2);
      } else {
        this.PriceTTC = 0;
      }
    },
    GetAllCategoryHave() {
      axios
        .get(`${API_URL}/productsNational/productsByName`)
        .then((response) => {
          this.Cattegory = response.data;
        });
    },
    AddNewProduct() {
      this.SubmitForm = true;

      let formData = {
        "nationalSellArr": this.nationalSellForm
      };

      axios
        .post(`${API_URL}/nationalSell`, formData)
        .then((response) => {
          Vue.$toast.open({
            message: "Ajoutée",
            type: "success",
          });
          this.$emit("close");
        })
        .catch((err) => {
          Vue.$toast.open({
            message: err.response.data.error,
            type: "error",
          });
        });
    },
    UpdateProduct() {
      this.SubmitForm = true;

      // let formData = {
      //   product: this.CategoryChose,
      //   sellQuantity: this.CodeProduit,
      //   sellOffer: this.Produit,
      //   htPrice: this.PriceHT
      // };

      let formData = {
        "sumOf": {
          "sellQuantity": 0,
          "sellOffer": 0,
          "htPrice": 0
        },
        "nationalSellArr": this.nationalSellForm
      };

      this.nationalSellForm.map(el => {
        el.sellQuantity = parseFloat(el.sellQuantity)
        el.sellOffer = parseFloat(el.sellOffer)
        el.htPrice = parseFloat(el.htPrice)
      })

      axios
        .put(`${API_URL}/nationalSell/${this.PropsToProductPopup.Id}`, formData)
        .then((response) => {
          Vue.$toast.open({
            message: "Ajoutée",
            type: "success",
          });
          this.$emit("close");
        })
        .catch((err) => {
          Vue.$toast.open({
            message: err.response.data.error,
            type: "error",
          });
        });
    },
    GetDataByDefault() {
      if (this.PropsToProductPopup.Condition != "New") {
        axios
          .get(`${API_URL}/nationalSell/${this.PropsToProductPopup.Id}`)
          .then((response) => {
            // this.CategoryChose = response.data.product;
            // this.CodeProduit = response.data.sellQuantity;
            // this.PriceHT = response.data.sellOffer;
            // this.Produit = response.data.htPrice;
            this.nationalSellForm = response.data.nationalSellArr
          });
      }
    },
  },
  mounted() {
    this.GetConditionProps();
    this.GetDataByDefault();
    this.GetAllCategoryHave();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.PopupNewClient {
  position: fixed;
  width: 40%;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 30%;
  top: 5%;
  z-index: 100000;
  height: 700px;
  overflow-y: scroll;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  button {
    font-size: 17px;
    font-family: $primaryFont;
    font-weight: 700;
    border-radius: 10px;
    padding: 6px 30px;
    background-color: $primaryColor;
    color: white;
    &:hover {
      border: 1px solid $primaryColor;
      background-color: transparent;
      color: $primaryColor;
    }
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;

    border: 2px solid gray;
    border-radius: 20px;
    margin-bottom: 2%;
    padding-left: 2%;
    .deleteAchat {
        cursor: pointer;
    }
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }

      .InputFaild,
      select {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  select {
    border: 1px solid #035a7f;
    width: 95%;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
  }
  .TCP {
    margin-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }
    .InputZone {
      width: 80%;
    }

    span {
      width: 20%;
      font-family: $primaryFont;
      font-size: 15px;
      font-weight: 700;
      color: #142048;
      cursor: pointer;
      &:hover {
        color: #b41206;
      }
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .NewCateg {
    display: flex;
    align-items: center;
    input {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      display: flex;
    }
  }
  h6 {
    font-family: $primaryFont;
    font-size: 20px;
    font-weight: 700;
    color: #142048;
  }
  .LesDepot {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .SingleDépot {
      width: 220px;
      padding: 6px 0px;
      h6 {
        font-family: $primaryFont;
        font-size: 12px;
        font-weight: bold;
        color: #142048;
      }
      input {
        border: 1px solid #035a7f;
        width: 95%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
      }
    }
  }
  .AutreUnite {
    padding-top: 10px;
    display: flex;
    align-items: center;

    span {
      font-family: $primaryFont;
      font-size: 15px;
      font-weight: 700;
      color: #142048;
      margin-right: 10px;
      width: 30%;
      &:hover {
        cursor: pointer;
        color: #b41206;
      }
    }
    input {
      width: 70%;
      border: 1px solid #035a7f;
      padding: 6px;
      border-radius: 6px;
    }
  }
  .AutreDepot {
    // display: flex;
    align-items: center;
    padding-bottom: 30px;
    justify-content: space-between;

    span {
      font-family: $primaryFont;
      font-size: 15px;
      font-weight: 700;
      color: #142048;
      margin-right: 10px;
      width: 20%;
      &:hover {
        cursor: pointer;
        color: #b41206;
      }
    }
    input {
      width: 30%;
      border: 1px solid #035a7f;
      padding: 6px;
      border-radius: 6px;
    }
    .ZoneAddDépot {
      display: flex;
      align-items: center;
      padding-top: 10px;
      justify-content: space-between;
      input {
        margin: 0px 20px;
        width: 30%;
      }
      button {
        font-size: 15px;
        border-radius: 4px;
      }
    }
  }
}
</style>
