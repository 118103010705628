import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from 'vue-sweetalert2';

// import VueSocketIO from "vue-socket.io";
// import io from 'socket.io-client';

// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: io("http://localhost:3001"), // options object is Optional
//   })
// );

Vue.config.productionTip = false;

import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)

import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(VueToast);
//Vue.$toast.open({/* options */});
let instance = Vue.$toast.open("You did it!");

// Force dismiss specific toast
instance.dismiss();
// Dismiss all opened toast immediately
Vue.$toast.clear();

new Vue({
  router,
  store,
  vuetify,

  render: function (h) {
    return h(App);
  },
}).$mount("#app");
