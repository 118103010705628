<template>
  <div>
    <div class="editeAndLogout">
      <v-btn fab dark color="red" @click="logoutFun">
        <v-icon dark> mdi-power </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logoutFun() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.editeAndLogout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    margin: 0% 2%;
    position: relative;
    top: 10px;
  h2 {
    font-size: 20px;
    position: relative;
    margin: 3% 8% 0% 0%;
    cursor: pointer;
    top: 0px;
    transition: all 0.2s ease-in-out;
    color: #00ABC4;
    background-color: #E1E1E1;
    padding: 1% 5%;
    border-radius: 30px;
    &:hover {
      background-color: #1cb6b6;
      color: white;
    }
  }
}
</style>
