<template>
  <div>
    <div class="RapportDeVente BonLivraison">
            <h2 v-if="ModeOperation == 'Vente'">Bon de livraison</h2>
            <h2 v-if="ModeOperation == ''">Bon de réception</h2>
            <h1>{{ModeOperation}}</h1>
            <div class="QRCODE">
                QR CODE
            </div>

            <div v-if="ModeOperation == 'Vente'">
                <div class="DownlodPdf" v-for="Stock,n in AllStockWeHave" :key="n">
                    <button @click="DownloadBonLivraison(Stock, n)">Telecharger BL ({{Stock}})</button>
                </div>
            </div>
            <div v-if="ModeOperation == 'Avoir'">
                <div class="DownlodPdf" v-for="Stock,n in AllStockWeHave" :key="n">
                    <button @click="DownloadBonResiption(Stock, n)">Telecharger BR ({{Stock}})</button>
                </div>
            </div>

    </div>
  </div>
</template>

<script>

import Swal from 'sweetalert2'

import axios from "axios";
import API_URL from "../../config";

export default {
 props:["RapportId"],
  data() {
    return {
        AllStockWeHave:[],
        Id:'',
        ForId:'',
        ModeOperation: ""
    };
  },
  created() {
  },
  methods: {
    GetRapportData(){
            axios.get(`${API_URL}/rapport/getOne/${this.RapportId}`).then((response) => {
                console.log(response.data)
                this.ModeOperation = response.data.ModeOperation
                let allStock = []
                response.data.MagasinStock.forEach(stock => {
                    stock.forEach(SingleStock =>{
                        if(!(allStock.includes(SingleStock.city)))
                            allStock.push(SingleStock.city)
                    })
                })
                this.AllStockWeHave = allStock
                this.Id = response.data._id,
                this.ForId = response.data.for._id
                console.log(allStock)

            });
    },
    DownloadBonLivraison(City, index){
        window.open(`${API_URL}/PDF/DownloadRapportFromBack/${this.Id}/${this.ForId}/${City}/BL?indexOf=${index}`, '_blank');
    },
    DownloadBonResiption(City, index) {
        window.open(`${API_URL}/PDF/DownloadRapportFromBackAvoir/${this.Id}/${this.ForId}/${City}/BL/BR?indexOf=${index}`, '_blank');
    }
  },
  mounted(){
      this.GetRapportData()
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;
.BonLivraison{
    width:40% !important;
    left: 30%;
}
.RapportDeVente{
    width:50%;
    left: 30%;
    position: absolute;
    z-index: 100;
    background:white;
    border-radius: 10px;
    box-shadow: 0px 0px 11px -4px;
    text-align: center;
    padding:20px;
    h2,h1{
        font-family:$primaryFont ;
        font-size: 25px;
        font-weight:600;
        color:$primary-background-color;
    }
    h1{
        font-size: 40px !important;
        font-weight: bold;
    }
    p{
        font-size: 25px !important;
        font-weight: 600;
        text-align: start;
        line-height: 30px;
    }
    table{
        width:100%;
        th{
            background-color:#142048;
            font-size: 20px;
            color:white;
            padding:10px 0px;
        }
        td{
            font-size: 20px;
            color:#142048;
            padding:10px 0px;
            border: 1px solid black;
            font-weight: bold;
        }
    }
    .QRCODE{
        padding: 40px;
    }
    .DownlodPdf{
        button{
            padding:10px 40px;
            border-radius: 30px;
            font-weight: bold;
            background-color: green;
            color:white;
                        display:block !important;
            margin: 10px auto;
            &:hover{
                 background-color: transparent;
                color:green;
                border:1px solid green;
            }

        }
    }

}

</style>

