<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />

    <div class="tableContents">
      <v-row>
        <v-col cols="6">
          <TcpsTable />
        </v-col>
        <v-col cols="6">
          <ProductsTable />
        </v-col>
        <v-col cols="12">
          <GeneraleTable />
        </v-col>
      </v-row>

    </div>
  </div>
</template>

<script>
import TcpsTable from "@/components/ChartlineTables/TcpsTable"
import ProductsTable from "@/components/ChartlineTables/ProductsTable"
import GeneraleTable from "@/components/ChartlineTables/GeneraleTable"

import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,

    TcpsTable,
    ProductsTable,
    GeneraleTable
  },
  data() {
    return {

    }
  },
};
</script>

<style lang="scss">
  .v-application .text-h2 {
    line-height: 0;
  }
  .v-date-picker-table {
    table {
      line-height: 0;
    }
  }
</style>
