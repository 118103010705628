<template>
  <div>
    <div
      class="BalckPage"
      v-if="OpenCompanyFeesPopup"
      @click="CloseFuntion"
    ></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <CompanyFees
      v-if="OpenCompanyFeesPopup"
      :PropsToCompanyFeesPopup="PropsToCompanyFeesPopup"
      @Close="CloseFuntion()"
    />

    <div class="ListClient">
      <div class="OuClientAndBtn">
        <h1>Frais de société</h1>
      </div>

      <div class="chargeListBtn">
        <div class="FilterParCatég">
          <h6>Etat (Aprové) :</h6>
          <select v-model="selectedStatus" @change="handleFilterChange">
            <option value="all">Tous</option>
            <option value="true">Aprové</option>
            <option value="false">Non aprové</option>
          </select>
        </div>

        <div class="FilterParCatég">
          <h6>Etat (Justifié) :</h6>
          <select v-model="selectedJustifyStatus" @change="handleFilterChange">
            <option value="all">Tous</option>
            <option value="true">Justifié</option>
            <option value="false">Non Justifié</option>
          </select>
        </div>

        <div class="FilterParCatég">
          <h6>Résponsable :</h6>
          <select v-model="selectedResponsable" @change="handleFilterChange">
            <option value="all">Tous</option>
            <option
              v-for="(listOfResponsable, index) in listOfResponsables"
              :key="index"
              :value="listOfResponsable._id"
            >
              {{ listOfResponsable.Name }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Période :</h6>
          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#1cb6b6"
                style="color: white"
              >
                Choisir une Période
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-text>
                  <div class="text-h2 pa-12">
                    <v-row justify="center">
                      <v-date-picker v-model="pickerDateOne" range>
                      </v-date-picker>

                      <v-btn
                        color="#1cb6b6"
                        style="color: white"
                        @click="getCompanyFeeBySelectedDate"
                        >valider</v-btn
                      >
                    </v-row>
                  </div>
                </v-card-text>
                <!-- <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions> -->
              </v-card>
            </template>
          </v-dialog>
        </div>
        <div class="FilterParCatég"></div>
      </div>

      <div class="chargeListBtn">
        <div class="btn_icon">
          <v-btn color="#1cb6b6" dark @click="showCompanyFeesType('CV', true)">
            Charges véhicules
          </v-btn>
          <v-btn
            @click="
              (PropsToCompanyFeesPopup.Condition = 'Show'),
                (PropsToCompanyFeesPopup.Type = 'CV'),
                (OpenCompanyFeesPopup = true)
            "
            color="success"
            fab
            small
            dark
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div class="btn_icon">
          <v-btn color="#1cb6b6" dark @click="showCompanyFeesType('FD', true)">
            Frais de déplacement
          </v-btn>
          <v-btn
            @click="
              (PropsToCompanyFeesPopup.Condition = 'Show'),
                (PropsToCompanyFeesPopup.Type = 'FD'),
                (OpenCompanyFeesPopup = true)
            "
            color="success"
            fab
            small
            dark
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div class="btn_icon">
          <v-btn color="#1cb6b6" dark @click="showCompanyFeesType('TL', true)">
            Frais de transport local
          </v-btn>
          <v-btn
            @click="
              (PropsToCompanyFeesPopup.Condition = 'Show'),
                (PropsToCompanyFeesPopup.Type = 'AT'),
                (OpenCompanyFeesPopup = true)
            "
            color="success"
            fab
            small
            dark
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <h2 v-if="selectedCompanyFee == 'CV'">Charges véhicules</h2>
      <h2 v-if="selectedCompanyFee == 'FD'">Frais de déplacement</h2>
      <h2 v-if="selectedCompanyFee == 'TL'">Frais de transport local</h2>

      <div class="d-flex justify-content-end container" v-if="isMOnthlyRapport">
        <v-btn color="success" fab x-large dark>
          <v-icon @click="downloadAllCompanyFeePDF">mdi-download-box</v-icon>
        </v-btn>
      </div>
      <div class="HeaderTable" v-if="selectedCompanyFee">
        <h3>Date</h3>
        <h3>Type</h3>
        <h3 v-if="selectedCompanyFee == 'TL' || isMOnthlyRapport">Départ</h3>
        <h3 v-if="selectedCompanyFee == 'TL' || isMOnthlyRapport">Arrivée</h3>
        <h3
          v-if="
            selectedCompanyFee == 'CV' ||
            selectedCompanyFee == 'FD' ||
            isMOnthlyRapport
          "
        >
          Détails
        </h3>
        <h3>Responsable</h3>
        <h3 v-if="selectedCompanyFee == 'CV' || isMOnthlyRapport">Véhicule</h3>
        <h3>Etat</h3>
        <h3>Etat</h3>
        <h3>Description</h3>
        <h3>Montant</h3>
        <h3 v-if="!isMOnthlyRapport">BON / PDF</h3>
        <h3 v-if="!isMOnthlyRapport">Validation</h3>
        <h3 v-if="!isMOnthlyRapport">Validation DG</h3>
        <h3>Action</h3>
      </div>
      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>

      <div class="TableContentZone" v-if="selectedCompanyFee">
        <div
          class="HeaderBody"
          v-for="(listOfCompanyFee, index) in listOfCompanyFees"
          :key="index"
          :style="
            index % 2 == 0
              ? 'background-color: rgba(5,139,132,0.22)'
              : 'background-color: rgba(180,181,181,0.22);'
          "
        >
          <h3>{{ listOfCompanyFee.formatedDate }}</h3>
          <h3>
            {{
              listOfCompanyFee.typeOfOp === "CV"
                ? "Charges véhicules"
                : listOfCompanyFee.typeOfOp === "FD"
                ? "Frais de déplacement"
                : "Frais de transport local"
            }}
          </h3>
          <h3 v-if="selectedCompanyFee == 'TL' || isMOnthlyRapport">
            {{ listOfCompanyFee.startedPoint }}
          </h3>
          <h3 v-if="selectedCompanyFee == 'TL' || isMOnthlyRapport">
            {{ listOfCompanyFee.endPoint }}
          </h3>
          <h3
            v-if="
              selectedCompanyFee == 'CV' ||
              selectedCompanyFee == 'FD' ||
              isMOnthlyRapport
            "
          >
            {{ listOfCompanyFee.details }}
          </h3>
          <h3>{{ listOfCompanyFee.responsable.Name }}</h3>
          <h3 v-if="selectedCompanyFee == 'CV' || isMOnthlyRapport">
            {{ listOfCompanyFee.vehicule }}
          </h3>
          <h3>{{ listOfCompanyFee.isJustify ? "Justifié" : "non Justifié" }}</h3>
          <h3>{{ listOfCompanyFee.isAproved ? "Aprové" : "non Aprové" }}</h3>
          <h3 style="word-break: break-all">
            {{ listOfCompanyFee.description }}
          </h3>
          <h3>{{ listOfCompanyFee.montant }}</h3>
          <h3 class="d-flex justify-content-between" v-if="!isMOnthlyRapport">
            <v-btn
              color="#1cb6b6"
              @click="showUploadedFile(listOfCompanyFee.bonFile)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>

            <v-btn
              color="#1cb6b6"
              @click="showGeneratedFile(listOfCompanyFee._id)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </h3>

          <h3 v-if="!isMOnthlyRapport">
            <v-btn
              :color="
                listOfCompanyFee.cvValidationStaus == 'V'
                  ? 'grey lighten-1'
                  : listOfCompanyFee.cvValidationStaus == 'A'
                  ? 'error'
                  : 'success'
              "
              v-if="selectedCompanyFee == 'CV'"
            >
              {{
                listOfCompanyFee.cvValidationStaus == "V"
                  ? "Validée"
                  : listOfCompanyFee.cvValidationStaus == "A"
                  ? "Annulée"
                  : "En cours"
              }}
            </v-btn>
            <v-btn
              :color="
                listOfCompanyFee.fdValidationStatus == 'V'
                  ? 'grey lighten-1'
                  : listOfCompanyFee.fdValidationStatus == 'A'
                  ? 'error'
                  : 'success'
              "
              v-if="selectedCompanyFee == 'FD'"
            >
              {{
                listOfCompanyFee.fdValidationStatus == "V"
                  ? "Validée"
                  : listOfCompanyFee.fdValidationStatus == "A"
                  ? "Annulée"
                  : "En cours"
              }}
            </v-btn>
            <v-btn
              :color="
                listOfCompanyFee.tlValidationStatus == 'V'
                  ? 'grey lighten-1'
                  : listOfCompanyFee.tlValidationStatus == 'A'
                  ? 'error'
                  : 'success'
              "
              v-if="selectedCompanyFee == 'TL'"
            >
              {{
                listOfCompanyFee.tlValidationStatus == "V"
                  ? "Validée"
                  : listOfCompanyFee.tlValidationStatus == "A"
                  ? "Annulée"
                  : "En cours"
              }}
            </v-btn>
          </h3>
          <h3 v-if="!isMOnthlyRapport">
            <v-btn
              color="success"
              v-if="listOfCompanyFee.dgValidationStatus == 'N'"
              >En cours</v-btn
            >
            <v-btn
              color="grey lighten-1"
              v-if="listOfCompanyFee.dgValidationStatus == 'V'"
              >Validée</v-btn
            >
            <v-btn
              color="error"
              v-if="listOfCompanyFee.dgValidationStatus == 'A'"
              >Annulée</v-btn
            >
          </h3>
          <h3>
            <svg
              v-if="selectedCompanyFee === 'CV'"
              @click="
                (PropsToCompanyFeesPopup.Condition = 'Update'),
                (PropsToCompanyFeesPopup.Type = 'CV'),
                (PropsToCompanyFeesPopup.Data = listOfCompanyFee),
                (OpenCompanyFeesPopup = true)
              "
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
              />
            </svg>
            <svg
              v-if="selectedCompanyFee === 'FD'"
              @click="
                (PropsToCompanyFeesPopup.Condition = 'Update'),
                (PropsToCompanyFeesPopup.Type = 'FD'),
                (PropsToCompanyFeesPopup.Data = listOfCompanyFee),
                (OpenCompanyFeesPopup = true)
              "
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
              />
            </svg>
            <svg
              v-if="selectedCompanyFee === 'TL'"
              @click="
                (PropsToCompanyFeesPopup.Condition = 'Update'),
                (PropsToCompanyFeesPopup.Type = 'AT'),
                (PropsToCompanyFeesPopup.Data = listOfCompanyFee),
                (OpenCompanyFeesPopup = true)
              "
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
              />
            </svg>
            <svg
              @click="handleDeleteFee(listOfCompanyFee._id)"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
              />
            </svg>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import CompanyFees from "../components/CompanyFeesPopup/addNewFees.vue";
// import Swal from 'sweetalert2'
// import Vue from "vue";

import axios from "axios";
import API_URL from "../../config";

import SOCKETS_URL from "../../configSocketsURL";
import io from "socket.io-client";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    CompanyFees,
  },
  data() {
    return {
      OpenCompanyFeesPopup: false,
      PropsToCompanyFeesPopup: {
        Condition: "",
        Type: "",
        Id: "",
        Data: ""
      },
      AllClientHave: [],
      AllTCP: [],
      TCPFilterd: "Tout",
      ClientFilterd: "",
      ICEFiltred: "",
      CINFiltred: "",
      VideData: false,

      client_switch: false,

      emptyCINorICE: false,

      ClientCode: "",

      sockets: io.connect(`${SOCKETS_URL}`),

      listOfCompanyFees: null,

      selectedCompanyFee: null,

      listOfResponsables: null,
      selectedResponsable: "all",
      selectedStatus: "all",
      selectedJustifyStatus: "all",
      selectedTypeOfOP: null,

      pickerDateOne: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      selectedDate: "",

      isMOnthlyRapport: false,
    };
  },
  created() {
    this.getListOfResponsable();
    // this.selectedCompanyFee = 'CV'
    // this.showCompanyFeesType('CV')
    // getAllRecouvremmentDataAfterUpdateClientStatus
    // this.sockets.on("getAllRecouvremmentDataAfterUpdateClientStatus", () => {
    //   this.FunctionToGetAllRapportVisiteDeRecouvrement();
    // });
  },
  computed: {},
  methods: {
    handleFilterChange() {
      this.showCompanyFeesType(this.selectedCompanyFee, false)
    },
    handleDeleteFee(clientID) {
      this.$swal({
        title: "Voulez vous supprimer ?",
        confirmButtonText: `Supprimer`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.delete(`${API_URL}/companyFees/deleteCompanyFee/${clientID}`)
            this.$swal("cette operation a été supprimée!", "", "success")

            this.showCompanyFeesType(this.selectedCompanyFee, false)
          }
          catch(err) {
            if (err.response && err.response.data.error) {
              this.$swal(err.response.data.error, "", "error");
            }
          }
        }
      })
    },
    downloadAllCompanyFeePDF() {
      window.open(
        `${API_URL}/companyFees/generateCompanyMonthlyFeesPdf_3/${this.selectedStatus}/${this.selectedJustifyStatus}/${this.selectedCompanyFee ? this.selectedCompanyFee : 'all'}/${this.selectedResponsable}?filtredDate=["${this.pickerDateOne[0]}", "${this.pickerDateOne[1]}"]`,
        "_blank"
      );
    },
    getCompanyFeeBySelectedDate() {
      if (this.pickerDateOne.length != 2)
        return this.$swal("Selectionner une période", "", "error");
      else if (!this.selectedStatus)
        return this.$swal("Selectionner etat", "", "error");
      else if (!this.selectedResponsable)
        return this.$swal("Selectionner une responsable", "", "error");

      this.isMOnthlyRapport = true;

      axios
        .get(
          `${API_URL}/companyFees/generateCompanyMonthlyFeesPdf/${this.selectedStatus}/${this.selectedJustifyStatus}/${this.selectedCompanyFee ? this.selectedCompanyFee : 'all'}/${this.selectedResponsable}?filtredDate=["${this.pickerDateOne[0]}", "${this.pickerDateOne[1]}"]`
        )
        .then((res) => {
          this.listOfCompanyFees = res.data;
        })
        .catch((err) => {
          this.$swal(err.response.data.error, "", "error");
        });
    },
    getListOfResponsable() {
      axios.get(`${API_URL}/user/getUserWithVehicul`).then((response) => {
        this.listOfResponsables = response.data;
      });
    },
    showCompanyFeesType(typeOf, hideOp) {
      if(this.selectedCompanyFee === typeOf && hideOp) {
        this.selectedCompanyFee = null
      }
      else {
        this.selectedCompanyFee = typeOf;

        this.isMOnthlyRapport = false;
        axios.get(`${API_URL}/companyFees/${typeOf}?isAproved=${this.selectedStatus}&isJustify=${this.selectedJustifyStatus}&responsable=${this.selectedResponsable}`).then((response) => {
          this.listOfCompanyFees = response.data;
        });
      }
    },
    showUploadedFile(file) {
      window.open(`${SOCKETS_URL}/${file}?token=ALDtIanysTac2023`, "_blank");
    },
    showGeneratedFile(id) {
      window.open(
        `${API_URL}/companyFees/generateCompanyFeesPdf/${id}`,
        "_blank"
      );
    },
    CloseFuntion() {
      this.OpenCompanyFeesPopup = false;
      this.showCompanyFeesType(this.selectedCompanyFee, false)
      // this.showCompanyFeesType("CV");

      // this.GetAllClient()
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00abc4;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.v-application .text-h2 {
  line-height: 0;
}

.v-date-picker-table {
  table {
    line-height: 0;
  }
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 4%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .chargeListBtn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3%;

    select,
    Input,
    textarea {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
      &:focus-visible {
        box-shadow: unset;
        border: 1px solid #035a7f;
        outline: unset;
      }
    }

    .btn_icon {
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-items: center;
      button {
        &:first-of-type {
          margin-bottom: 15px;
        }
      }
    }
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3, h2 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0px auto;
    .FilterParCatég {
      line-height: 0;
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>
