<template>
          <div class="tableContentsProducts">
          <div class="Filter">


            <div class="FilterParCatég">
              <h6>Filtrer par date  :</h6>
              <!-- <select v-model="selectedYear" @change="filtredYearFunc()">
                <option :value="listOfYear" v-for="(listOfYear, index) in listOfYears" :key="index">{{ listOfYear }}</option>
              </select> -->

          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#1cb6b6"
                style="color: white"
              >
                Choisir une Période
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-text>
                  <div class="text-h2 pa-12">
                    <v-row justify="center">
                      <v-date-picker v-model="pickerDateOne" range>
                      </v-date-picker>

                      <v-btn
                        color="#1cb6b6"
                        style="color: white"
                        @click="getTableDetailsBySelectedDate"
                        >valider</v-btn
                      >
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>

            </div>
          </div>

          <div class="Filter">
            <div class="FilterParCatég">
                <v-btn
                  @click="downloadExcelFile"
                  v-if="!tcpTableEcSpinner"
                  color="success"
                  fab
                  small
                  dark
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>TCP</th>
                <th>Produit</th>
                <th>Category</th>
                <th>Quantity</th>
                <th>Montant</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="tcpTableEcSpinner">
                  <tr>
                    <td colspan="3">
                      <v-progress-circular color="dark-blue" indeterminate :size="90"></v-progress-circular>
                    </td>
                  </tr>
              </template>

              <template
                v-if="
                  tcpFiltredDataEC.tcpTableData &&
                  tcpFiltredDataEC.tcpTableData.data &&
                  !tcpTableEcSpinner
                "
              >
                <template v-for="(value, key) in tcpFiltredDataEC.tcpTableData.data">
                  <tr v-for="(product, productKey) in value" :key="productKey">
                    <td>{{ key }}</td>
                    <td>{{ productKey }}</td>
                    <td>{{ product[0].Category }}</td>
                    <td>{{ product[product.length - 1].sumOfQuantity }}</td>
                    <td>{{ (product[product.length - 1].sumOfAmount).toFixed(2) }}</td>
                  </tr>
                  <tr class="finalCategoryRow" :key="key">
                    <td colspan="3"></td>
                    <td>{{ (getSumOfAmountForOneTCP(key).sumOfOneTcpQuantity) }}</td>
                    <td>{{ (getSumOfAmountForOneTCP(key).sumOfOneTcpAmount).toFixed(2) }}</td>
                  </tr>
                </template>

                  <tr class="finalCategoryRow2">
                    <td colspan="3"></td>
                    <td>{{ tcpFiltredDataEC.tcpTableData.sumOfQuantity }}</td>
                    <td>{{ (tcpFiltredDataEC.tcpTableData.sumOfAmount).toFixed(2) }}</td>
                  </tr>
              </template>
            </tbody>
          </table>
        </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";

import LogOut from "@/components/Logout";

import axios from "axios";
import API_URL from "@/../config";
import SOCKETS_URL from "@/../configSocketsURL";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      AllTCP: [],
      TCPFilterd: "all",
      selectedYear: new Date().getFullYear(),
      listOfYears: [],

      ProductsFilterd: "Tout",
      listProductsItems: null,

      tcpFiltredDataEC: {
        tcpTableData: null,
        selectedCategory: "Engrais classiques",
      },
      sumOfAllC: {
        sumOfAllQuantity: 0,
        sumOfAllAmount: 0,
      },

      ClientFilterd: "all",

      tcpTableEcSpinner: true,

      pickerDateOne: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ]
    };
  },
  computed: {

  },
  async created() {
    // this.featchData()
    this.tcpTableEcSpinner = true
    await this.getTcpTableEC();
    this.tcpTableEcSpinner = false

    await this.sumOfAllFunc();
  },
  methods: {
    async downloadExcelFile() {
      this.tcpTableEcSpinner = true
      try {
        let response = null;
        if (this.pickerDateOne.length != 2) {
          response = await axios.get(
            `${API_URL}/dashboard/GeneraleTableToExcel?TCP=${this.TCPFilterd}&Client=${this.ClientFilterd}`
          );
        } else {
          response = await axios.get(
            `${API_URL}/dashboard/GeneraleTableToExcel?TCP=${this.TCPFilterd}&Client=${this.ClientFilterd}&filtredDate=["${this.pickerDateOne[0]}", "${this.pickerDateOne[1]}"]`
          );
        }
        this.tcpTableEcSpinner = false

        if(response)
          window.open(`${SOCKETS_URL}/${response.data}?token=ALDtIanysTac2023`, '_blank')
      } catch (error) {
        console.error(error);
        this.tcpTableEcSpinner = false
      }
    },

    getSumOfAmountForOneTCP(TcpName) {
      const tcpData = this.tcpFiltredDataEC.tcpTableData.data[TcpName];
      if (tcpData) {
        const keys = Object.keys(tcpData);

        if (keys.length > 0) {
          const lastKey = keys[keys.length - 1];
          const lastProducts = tcpData[lastKey];

          if (Array.isArray(lastProducts) && lastProducts.length > 0) {
            const lastProduct = lastProducts[lastProducts.length - 1];
            return lastProduct;
          }
        }
      }
      return null;
    },

    async getTableDetailsBySelectedDate() {
      if (this.pickerDateOne.length != 2)
        return this.$swal("Selectionner une période", "", "error");

      await this.getAllTcpTableData()
    },

    async getAllTcpTableData() {
      this.tcpTableEcSpinner = true
      await this.getTcpTableEC();
      this.tcpTableEcSpinner = false

      await this.sumOfAllFunc()
    },

    async getTcpTableEC() {
      try {
        let response

        if(this.pickerDateOne.length != 2) {
          response = await axios.get(
            `${API_URL}/dashboard/GeneraleTable?currentYear=${this.selectedYear}&TCP=${this.TCPFilterd}&selectedProduct=${this.ProductsFilterd}`
          );
        }
        else {
          response = await axios.get(
            `${API_URL}/dashboard/GeneraleTable?currentYear=${this.selectedYear}&TCP=${this.TCPFilterd}&selectedProduct=${this.ProductsFilterd}&filtredDate=["${this.pickerDateOne[0]}", "${this.pickerDateOne[1]}"]`
          );
        }

        this.tcpFiltredDataEC.tcpTableData = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async sumOfAllFunc() {
      this.sumOfAllC.sumOfAllQuantity =
        this.tcpFiltredDataEC.tcpTableData.sumOfQuantity

      this.sumOfAllC.sumOfAllAmount =
        this.tcpFiltredDataEC.tcpTableData.sumOfAmount
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.5);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

$table-background-color: #f2f2f2;
$table-header-background-color: #1cb6b6;
$table-header-color: white;
$table-row-even-background-color: #f9f9f9;
$table-row-hover-background-color: #ddd;

.tableContentsProducts {
  width: 100%;
  margin-top: 5%;

  .Filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 10px auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: $table-background-color;

      th {
        background-color: $table-header-background-color;
        color: $table-header-color;
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: $table-row-even-background-color;
      }

      tr:hover {
        background-color: $table-row-hover-background-color;
      }

      .finalCategoryRow {
        background-color: $primaryBgColor2 !important;
        color: black;
        font-weight: bold;
        font-family: $primaryFont;
      }

      .finalCategoryRow2 {
        background-color: $primaryColor !important;
        color: black;
        font-weight: bold;
        font-family: $primaryFont;
        height: 100px;
        font-size: 22px;
      }
    }

    th,
    td {
      padding: 8px;
      text-align: center;
    }
  }
}
</style>
