<template>
  <div class="leftSideNav" :style="isMax ? 'width: 17%;' : 'width: 5%;'">
    <div class="sideBar">
      <div
        class="arrow"
        style="width: 100%; border-radius: 50px"
        @click="isMax = !isMax"
      >
        <p v-if="isMax"></p>
        <img
          src="@/assets/ardsvg/Arrow.svg"
          :style="
            isMax ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);'
          "
        />
      </div>
      <div class="sideBarContents">
        <div class="icons">
          <div v-for="(item, index) in items" :key="index" class="marginB">
            <router-link
              :to="item.url"
              :class="$route.path == item.url ? 'activeIcon icon' : 'icon'"
            >
              <!-- $route.path  activeIcon -->
              <div class="image">
                <img :src="require('./../assets/ardsvg/' + item.icon)" />
              </div>
              <p v-if="isMax">{{ item.title }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// .v-image__image--cover {
//   background-size: contain !important;
// }
$grayColorOne: #707070;
$grayColorTwo: #f3f3f3;
$redColor: #ff0000;

.sideBar {
  margin-top: 66px;
  .arrow {
    background-color: #00abc4;
    padding: 13px;
    box-shadow: 1px 3px 6px -1px $grayColorOne;
    height: 50px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    p {
      color: white;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 3px;
      transition: opacity 0.3s ease-in-out;
    }
    img {
      transition: transform 0.3s ease-in-out;
    }
  }
  .sideBarContents {
    background: linear-gradient(
      180deg,
      rgba(243, 243, 243, 1) 50%,
      rgba(223, 223, 223, 1) 100%
    );
    height: 75%;
    margin-top: 10%;
    border-radius: 30px;
    position: absolute;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    @media (max-width: 1800px) {
      height: 85%;
    }
    a {
      text-decoration: unset;
    }
    .icons {
      padding: 10px 15px;
      .marginB {
        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }
      .activeIcon {
        background-color: #d2d2d2;
      }
      .icon {
        padding: 5px 0px 0px 10px;
        border-radius: 30px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #d2d2d2;
          letter-spacing: 1px;
        }
        .image {
          border: 1px solid #8a8d8d;
          border-radius: 50%;
          padding: 5px;
          img {
            width: 25px;
            height: 25px;
          }
        }
        display: flex;
        flex-direction: row;
        align-items: baseline;
        p {
          color: $grayColorOne;
          font-weight: 500;
          width: 100%;
          margin-left: 10%;
          font-size: 14px;
        }
      }
    }
  }
}
.leftSideNav {
  float: left;
  position: relative;
  height: 100vh;
  margin-left: 3%;
  transition: width 0.3s ease-in-out;
  @media (max-width: 990px) {
    display: none;
  }
}
</style>

<script>
import jwt_decode from "jwt-decode";
export default {
  data() {
    return {
      items: [],
      isMax: true,
      drawer: true,
    };
  },
  methods: {
    reddirectTo(val) {
      this.$router.push(`${val}`);
    },
  },
  created() {
    var get_token = localStorage.getItem("token2");
    var decoded = jwt_decode(get_token);

    if (decoded.userRole == "Gestionnaire de stocks") {
      this.items.push({
        title: "Gestion de stock",
        icon: "Gestiondesstock.svg",
        url: `/GestiondeStock`,
      });
      this.items.push({
        title: "Transfer de stock",
        icon: "Params2.svg",
        url: `/TransferDeStock`,
      });
      this.items.push({
        title: "Gestion des operation",
        icon: "Params.svg",
        url: `/GestiondesOperation`,
      });
      this.items.push({
        title: "Mouvement des dépôts",
        icon: "Params2.svg",
        url: `/DParP`,
      });
      this.items.push({
        title: "Mouvement des produits",
        icon: "Params2.svg",
        url: `/PParD`,
      });
      this.items.push({
        title: "Invetaire",
        icon: "Params2.svg",
        url: `/Iventaire`,
      });
      this.items.push({
        title: "Frais de transport local",
        icon: "Params2.svg",
        url: `/Frais_transport_local`,
      });
    }
    else if (decoded.userRole == "Facturation") {
      this.items.push({
        title: "Facturation",
        icon: "Facturation.svg",
        url: `/Facturation`,
      });
      this.items.push({
        title: "Gestion des operation",
        icon: "Params.svg",
        url: `/GestiondesOperation`,
      });
      this.items.push({
        title: "Transfert de stock",
        icon: "Params2.svg",
        url: `/TransferDeStock`,
      });

      this.items.push({
        title: "Recouvrement",
        icon: "Facturation.svg",
        url: `/Recouvrement`,
      });
      this.items.push({
        title: "LVJ",
        icon: "Facturation.svg",
        url: `/LVJ`,
      });
      this.items.push({
        title: "JVC",
        icon: "Facturation.svg",
        url: `/JVC`,
      });
      this.items.push({
        title: "EE",
        icon: "Facturation.svg",
        url: `/EE`,
      });
      this.items.push({
        title: "Frais de société",
        icon: "Facturation.svg",
        url: `/CompanyFees`,
      });
    } else if (decoded.userRole == "Achat national") {
      this.items.push({
        title: "Achat national",
        icon: "Facturation.svg",
        url: `/AchatNationnal`,
      });
      this.items.push({
        title: "List des produits",
        icon: "Params.svg",
        url: `/ListDesProduitsNationnal`,
      });
    } else if (decoded.userRole == "Technical Department") {
      this.items.push({
        title: "Gestion des essay",
        icon: "Params2.svg",
        url: `/GestionStockVE`
      });
      this.items.push({
        title: "Gestion des operation",
        icon: "Params2.svg",
        url: `/GestiondesOperation`
      });
      this.items.push({
        title: "Stock essay",
        icon: "Params2.svg",
        url: `/EssayStock`
      })
    } else if (decoded.userRole == "relation client") {
      this.items.push({
        title: "List des clients",
        icon: "Params2.svg",
        url: `/EE`,
      });
      this.items.push({
        title: "Charge vehicules",
        icon: "Params2.svg",
        url: `/Charges_vehicules`,
      });
      this.items.push({
        title: "Frais de société",
        icon: "Params.svg",
        url: `/CompanyFees`,
      });
    }
    else if (decoded.userRole == "D.Commercial") {
      this.items.push({
        title: "Frais de déplacement",
        icon: "Params2.svg",
        url: `/Frais_deplacement`,
      });
      this.items.push({
        title: "Iventaire",
        icon: "Params2.svg",
        url: `/Iventaire`,
      });
      this.items.push({
        title: "Chartline",
        icon: "Params2.svg",
        url: `/chartline`,
      });
      this.items.push({
        title: "Gestion des operation",
        icon: "Params.svg",
        url: `/GestiondesOperation`,
      });
    }
    else if (decoded.userRole == "D.General") {
      this.items.push({
        title: "Frais de société DG",
        icon: "Params2.svg",
        url: `/Frais_DG`,
      });
    }
    else if (decoded.userRole == "parc auto") {
      this.items.push({
        title: "Charges vehicules",
        icon: "Params2.svg",
        url: `/Charges_vehicules`,
      });
    }
  },
};
</script>
