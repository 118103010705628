<template>
  <div class="PopupReleve">
    <h4>Relevée</h4>

    <div class="Filter">
        <div class="FilterParCatég">
            <h6>Date :</h6>
            <select v-model="currentYear" @change="filtredYear()">
                <option v-for="(allYear, index) in allYears" :key="index" :value="allYear">{{ allYear }}</option>
            </select>
        </div>

        <div class="FilterParCatég">
            <v-btn class="mx-2" fab dark large color="#058B84" @click="generateRelevePDF">
                <v-icon dark> mdi-printer-outline </v-icon>
            </v-btn>
        </div>
    </div>

    <table>
        <tr>
            <th>Date</th>
            <th>Jrl</th>
            <th>Libellé</th>
            <th>Débit</th>
            <th>Crédit</th>
            <th>Solde</th>
        </tr>
        <tr v-for="(DATA, index) in DATA" :key="index">
            <td>{{ DATA.Date }}</td>
            <td>{{ DATA.jtl }}</td>
            <td v-if="DATA.Type == 'Effet' || DATA.Type == 'Cheque' || DATA.type == 'IMP'">{{ DATA.Type }} <br> N°{{ DATA.Number }} <br> {{ DATA.DateEcheance }}</td>
            <td v-else-if="DATA.Type == 'Espece' || DATA.Type == 'Virement' || DATA.Type == 'Cheque_facture'">{{ DATA.Type }} <br> {{ DATA.Nfacture }}</td>
            <td v-else-if="DATA.jtl == 'VT' || DATA.jtl == 'AV'">{{ DATA.Type }} <br>#{{ DATA.FactureNumber }}</td>
            <td v-else></td>
            <td>{{ parseFloat(DATA.Debit).toFixed(2) }}</td>
            <td>{{ parseFloat(DATA.Credit).toFixed(2) }}</td>
            <td>{{ parseFloat(DATA.Solde).toFixed(2) }}</td>
        </tr>
        <tr class="sumOfRow">
            <td colspan="3"></td>
            <td>{{ sumOfAmount.D }}</td>
            <td>{{ sumOfAmount.C }}</td>
            <td></td>
        </tr>
    </table>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import Vue from "vue";
import axios from "axios";
import API_URL from "@/../config";

export default {
  props: ["PropsToRelevePopup"],
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
        isEmptyData: true,
        currentYear: new Date().getFullYear(),
        DATA: null,
        sumOfAmount: 0,
        allYears: null,

        currentYearD: 0,
        currentYearC: 0,

        relevePrevYearsSold: {

        },

        sumOfYearlyPrice: 0
    };
  },
  async created() {
    // await this.getPrevYearSold()
    // await this.getPrevYearSold2()

    await this.getCurrentSold()

    await this.getAllReleve()
    await this.getAllYear()
  },
  methods: {
    async getCurrentSold() {
        await axios.get(`${API_URL}/rapport/relevePrevYears?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=${new Date().getFullYear()}`)
        await axios.get(`${API_URL}/rapport/relevePrevYears?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=${new Date().getFullYear()-1}`)
    },
    // async getPrevYearSold2() {
    //     // const calculatedRFrom = 2023

    //     // for(let i = calculatedRFrom-1; i <= parseInt(this.currentYear); i++) {
    //     //     await axios.get(`${API_URL}/rapport/relevePrevYears?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=${i}&calculatedFrom=${calculatedRFrom}&clientD=459122.29&clientC=0`)
    //     //     .then(response => {
    //     //         this.relevePrevYears[i] = response.data.Solde
    //     //     })
    //     // }

    //     // console.log(this.PropsToRelevePopup.Id)

    //     await axios.get(`${API_URL}/codeClient/WEB/OneClientDetails/${this.PropsToRelevePopup.Id}`)
    //     .then(response => {
    //         this.sumOfYearlyPrice = response.data[0].ClientSumOffFPrice
    //     })

    //     await axios.get(`${API_URL}/rapport/relevePrevYears?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=2023&calculatedFrom=2021&clientD=${this.sumOfYearlyPrice}&clientC=0`)
    //     .then(response => {
    //         if(response.data.Solde >= 0) {
    //             this.currentYearD = response.data.Solde
    //             this.currentYearC = 0
    //         }
    //         else {
    //             this.currentYearD = 0
    //             this.currentYearC = response.data.Solde
    //         }
    //     })

    //     await axios.get(`${API_URL}/rapport/releveYears?codeClient=${this.PropsToRelevePopup.ClientCode}`)
    //     .then(response => {
    //         response.data.map(async el => {
    //             await axios.get(`${API_URL}/rapport/relevePrevYears?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=${el}&calculatedFrom=2021&clientD=${this.relevePrevYearsSold[el] ? this.relevePrevYearsSold[el] : this.sumOfYearlyPrice}&clientC=0`)
    //             .then( async response2 => this.relevePrevYearsSold[el] = parseFloat(parseFloat(response2.data.Solde).toFixed(2)) )
    //         })
    //     })
    // },

    // async getPrevYearSold() {
    //     await axios.get(`${API_URL}/rapport/releve?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=${parseInt(this.currentYear)-1}&clientD=0&clientC=0`)
    //     .then(response => {
    //         this.currentYearD = response.data.sumOfReleve.D
    //         this.currentYearC = response.data.sumOfReleve.C
    //     })
    // },
    async getAllReleve() {
        await axios.get(`${API_URL}/rapport/releve?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=${this.currentYear}`)
        .then(response => {
            this.isEmptyData = false
            this.DATA = response.data.releveData
            this.sumOfAmount = response.data.sumOfReleve
        })
        .catch(err => {
            this.isEmptyData = true
            if (err.response.data.error)
                console.log(err.response.data.error)
        })
    },
    async getAllYear() {
        await axios.get(`${API_URL}/rapport/releveYears?codeClient=${this.PropsToRelevePopup.ClientCode}`)
        .then(response => {
            this.allYears = response.data
        })
        .catch(err => {
            if (err.response.data.error)
                console.log(err.response.data.error)
        })
    },
    filtredYear() {
        this.getAllReleve()
        this.getAllYear()
    },

    generateRelevePDF() {
        window.open(`${API_URL}/rapport/relevePDF?userId=${this.PropsToRelevePopup.ClientCode}&currentYear=${this.currentYear}&calculatedFrom=2024&clientD=${this.currentYearD}&clientC=${this.currentYearC}`, "_blank");
    }
  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0px auto;
    margin-bottom: 2%;
    .FilterParCatég {
        width: 25%;
        text-align: center;
        h6 {
            color: $primaryColor;
        }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
      &:focus-visible {
        border: 1px solid #035a7f;
      }
    }
  }

.PopupReleve {
    position: fixed;
    width: 40%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 11px -4px black;
    left: 30%;
    top: 10%;
    z-index: 100000;
    padding: 40px 40px 100px;
    overflow-y: scroll;
    height: 80%;
    h4 {
        font-family: $primaryFont;
        font-size: 30px;
        font-weight: bold;
        color: $primary-background-color;
        padding-bottom: 30px;
    }
    button {
        font-size: 17px;
        font-family: $primaryFont;
        font-weight: 700;
        border-radius: 10px;
        padding: 6px 30px;
        background-color: $primary-background-color;
        color: white;
        &:hover {
            border: 1px solid $primary-background-color;
            background-color: transparent;
            color: $primary-background-color;
        }
    }
    table {
        width:100%;
        th{
            background-color:#058B84;
            font-size: 20px;
            color:white;
            padding:10px 0px;
            text-align: center;
        }
        .sumOfRow {
            background-color:#058B84;
            font-size: 20px;
            color:white;
            padding:10px 0px;
            text-align: center;
        }
        tr {
            border-width: 2px;
            td{
                font-size: 15px;
                
                padding:10px 0px;
                border: 1px solid #058B84;
                font-weight: bold;
                text-align: center;
            }
        }
    }
}
</style>
