import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login"

import GestiondesOperation from "../views/GestionOperation"
import GestiondesOperation2 from "../views/GestionOperation2"

import GestionDeStock from "../views/GestionStock"
import TransferDeStock from '../views/TransferDeStock'
import TransferDeStock2 from '../views/TransferDeStock2'
import DepotParProduit from '../views/DepotParProduit'
import ProduitParDepot from '../views/ProduitParDepot'
import Iventaire from '../views/Iventaire'

import Facturation from "../views/Facturation"

import LVJ from '../views/LVJournalier'
import JVC from '../views/JVcomptable'
import EE from '../views/EEncaissements'

import AchatNationnal from '../views/AchatNationnal'
import ListDesProduitsNationnal from '../views/ListDesProduitsNationnal'

import LC from "../views/ListClient"

import Charges_vehicules from "../views/Charges_vehicules"
import Frais_deplacement from "../views/Frais_deplacement"
import Frais_transport_local from "../views/Frais_transport_local"
import Frais_DG from "../views/Frais_DG"

import GestionStockVE from '../views/GestionStockVE'

import EssayStock from "../views/EssayStock"

import Recouvrement from '../views/Recouvrement'

import CompanyFees from "../views/company_fees"

import Chartline from "../views/Chartline"

import axios from "axios";
import API_URL from "../../config";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/AchatNationnal",
    name: "AchatNationnal",
    component: AchatNationnal,
  },
  {
    path: "/CompanyFees",
    name: "CompanyFees",
    component: CompanyFees,
  },
  {
    path: "/Recouvrement",
    name: "Recouvrement",
    component: Recouvrement,
  },
  {
    path: "/ListDesProduitsNationnal",
    name: "ListDesProduitsNationnal",
    component: ListDesProduitsNationnal,
  },
  {
    path: "/GestiondeStock",
    name: "GestionDeStock",
    component: GestionDeStock,
  },
  {
    path: "/GestiondesOperation",
    name: "GestiondesOperation",
    component: GestiondesOperation
  },
  {
    path: "/GestiondesOperation2",
    name: "GestiondesOperation2",
    component: GestiondesOperation2
  },
  {
    path: "/TransferDeStock",
    name: "TransferDeStock",
    component: TransferDeStock
  },
  {
    path: "/TransferDeStock2",
    name: "TransferDeStock2",
    component: TransferDeStock2
  },
  {
    path: "/DParP",
    name: "DParP",
    component: DepotParProduit
  },
  {
    path: "/PParD",
    name: "PParD",
    component: ProduitParDepot
  },
  {
    path: "/Iventaire",
    name: "Iventaire",
    component: Iventaire
  },

  {
    path: "/Facturation",
    name: "Facturation",
    component: Facturation,
  },

  {
    path: "/LVJ",
    name: "LVJ",
    component: LVJ
  },
  {
    path: "/JVC",
    name: "JVC",
    component: JVC
  },
  {
    path: "/EE",
    name: "EE",
    component: EE
  },

  {
    path: "/Charges_vehicules",
    name: "Charges_vehicules",
    component: Charges_vehicules
  },
  {
    path: "/Frais_deplacement",
    name: "Frais_deplacement",
    component: Frais_deplacement
  },
  {
    path: "/Frais_transport_local",
    name: "Frais_transport_local",
    component: Frais_transport_local
  },
  {
    path: "/LC",
    name: "LC",
    component: LC
  },
  {
    path: "/GestionStockVE",
    name: "GestionStockVE",
    component: GestionStockVE,
  },
  {
    path: "/EssayStock",
    name: "EssayStock",
    component: EssayStock
  },
  {
    path: "/Frais_DG",
    name: "Frais_DG",
    component: Frais_DG
  },

  {
    path: "/chartline",
    name: "Chartline",
    component: Chartline
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  var get_token = localStorage.getItem('token2')
  if (to.name === 'Facturation' || to.name === 'Frais_transport_local' || to.name === 'Frais_DG' || to.name === 'GestionDeStock' || to.name === 'JVC' || to.name === 'LVJ' || to.name === 'EE' || to.name === 'DParP' || to.name === 'PParD' || to.name === 'Iventaire' || to.name === 'Facturation' || to.name === 'LVJ' || to.name === 'JVC' || to.name === 'EE' || to.name === 'LC' && get_token) {
      var get_user_id = localStorage.getItem('user_id2')
      axios
      .get(`${API_URL}/user/${get_user_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${get_token}`
        }
      })
      .then(() => {
        return next()
      })
      .catch(err => {
          console.log(err.response.data.error)
          if (to.name === 'Facturation' || to.name === 'Frais_transport_local' || to.name === 'Frais_DG' || to.name === 'GestionDeStock' || to.name === 'JVC' || to.name === 'LVJ' || to.name === 'EE' || to.name === 'DParP' || to.name === 'PParD'  || to.name === 'Iventaire' || to.name === 'Facturation' || to.name === 'LVJ' || to.name === 'JVC' || to.name === 'EE' || to.name === 'LC' && err.response.data.error || err) next({ name: 'login' })
      });
  }
  else {
    if (to.name === 'Facturation' || to.name === 'Frais_transport_local' || to.name === 'Frais_DG' || to.name === 'GestionDeStock' || to.name === 'JVC' || to.name === 'LVJ' || to.name === 'EE' || to.name === 'DParP' || to.name === 'PParD'  || to.name === 'Iventaire' || to.name === 'Facturation' || to.name === 'LVJ' || to.name === 'JVC' || to.name === 'EE' || to.name === 'LC')
      next({ name: 'login' })
  }
  next()
})

export default router;
