<template>
  <div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />

    <div class="ListClient">
      <div class="Filter" style="margin-bottom: 20px">
        <div class="FilterParCatég">
          <h6>Filtrer par produit :</h6>
          <input
            type="text"
            placeholder="Tout"
            v-model="ProduitFiltred"
            @keyup="FilterByProduct"
          />
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par category :</h6>
          <select v-model="CatégoryFilterd" @change="FilterByCatégory()">
            <option value="Tout">Tout</option>
            <option v-for="(Catég, n) in Catégory" :key="n" :value="Catég">
              {{ Catég }}
            </option>
          </select>
        </div>
        <div class="FilterParCatég">
          <h6>Filtrer par depot :</h6>
          <select v-model="DepotFilterd" @change="FiltredByDepot()">
            <option value="Tout">Tout</option>
            <option v-for="(Depot, n) in AllDepot" :key="n" :value="Depot">
              {{ Depot }}
            </option>
          </select>
        </div>
      </div>

      <div class="HeaderTable">
        <h3>Code produit</h3>
        <h3>Produit</h3>
        <h3>Unité</h3>
        <h3>Dépot</h3>
        <h3>Disponible</h3>
      </div>

      <div v-if="!dataIsLoaded">
        <div class="spinner">
          <v-progress-circular
            :size="100"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>

      <div class="Vide" v-if="VideData">
        <h5>Vide</h5>
      </div>

      <div class="TableContentZone">
        <div v-for="(invetaire, index) in invetairesData" :key="index">
          <div class="HeaderBody" :style=" index % 2 == 0 ? 'background-color: rgba(5,139,132,0.22)' : 'background-color: rgba(180,181,181,0.22);'">
            <h3>{{ invetaire.CodeProduct }}</h3>
            <h3>{{ invetaire.Product }}</h3>
            <h3>{{ invetaire.Unite }}</h3>
            <h3>
              <template v-if="DepotFilterd == 'Tout'">
                <p
                  v-for="(Depot, depotIndex) in invetaire.Depot"
                  :key="depotIndex"
                  style="margin-bottom: 0"
                >
                  {{ Depot }}
                </p>
                <hr style="opacity: 0" v-if="invetaire.Dispo.length > 1" />
                <span style="opacity: 0" v-if="invetaire.Dispo.length > 1"
                  >100</span
                >
              </template>
              <template v-else>
                {{ invetaire.Depot }}
              </template>
            </h3>
            <h3>
              <template v-if="DepotFilterd == 'Tout'">
                <p
                  v-for="(Dispo, dispoIndex) in invetaire.Dispo"
                  :key="dispoIndex"
                  style="margin-bottom: 0"
                >
                  {{ Dispo }}
                </p>
                <hr v-if="invetaire.Dispo.length > 1" />
                <span v-if="invetaire.Dispo.length > 1">{{
                  invetaire.SumOfStockDisponible
                }}</span>
              </template>

              <template v-else>
                {{ invetaire.Dispo }}
              </template>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="ZoneTable">
      <table ref="document">
        <tr>
          <th>Code produit</th>
          <th>Produit</th>
          <th>Unité</th>
          <th>Dépot</th>
          <th>Disponible</th>
        </tr>
        <tr v-for="(invetaire, index) in invetairesData" :key="index">
          <td>{{ invetaire.CodeProduct }}</td>
          <td>{{ invetaire.Product }}</td>
          <td>
            <p>{{ invetaire.Unite }}</p>
          </td>
          <td>
            <template v-if="DepotFilterd == 'Tout'">
              <p
                v-for="(Depot, depotIndex) in invetaire.Depot"
                :key="depotIndex"
                style="margin-bottom: 0"
              >
                {{ Depot }}
              </p>
              <hr style="opacity: 0" v-if="invetaire.Dispo.length > 1" />
              <span style="opacity: 0" v-if="invetaire.Dispo.length > 1"
                >100</span
              >
            </template>
            <template v-else>
              {{ invetaire.Depot }}
            </template>
          </td>
          <td>
            <template v-if="DepotFilterd == 'Tout'">
              <p
                v-for="(Dispo, dispoIndex) in invetaire.Dispo"
                :key="dispoIndex"
                style="margin-bottom: 0"
              >
                {{ Dispo }}
              </p>
              <hr v-if="invetaire.Dispo.length > 1" />
              <span v-if="invetaire.Dispo.length > 1">{{
                invetaire.SumOfStockDisponible
              }}</span>
            </template>

            <template v-else>
              {{ invetaire.Dispo }}
            </template>

          </td>
        </tr>
      </table>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "../../config";
import html2pdf from "html2pdf.js";
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

export default {
  //   emit: ["Close"],
  //   props: ["PropsToInvetairePopup"],
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut
  },
  data() {
    return {
      getTheData: false,
      invetairesData: [],
      CatégoryFilterd: "Tout",
      Catégory: [],

      AllDepot: [],
      DepotFilterd: "Tout",

      ProduitFiltred: "",

      VideData: false,
      dataIsLoaded: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    FilterByProduct() {
      this.getAllInvetaires();
      // .then(el => {
      //   this.invetairesData = el.filter(el => el.Product.includes(this.ProduitFiltred))
      // })
    },
    FilterByCatégory() {
      this.getAllInvetaires();
    },
    FiltredByDepot() {
      this.getAllInvetaires();
      // if(this.DepotFilterd == "Tous")
      //   this.getAllInvetaires()
      // else {
      //   this.getAllInvetaires().then(el => {
      //     this.invetairesData = el.filter(el => el.Depot.includes(this.DepotFilterd))
      //   })
      // }
    },
    GetAllElementFiltred() {
      axios.get(`${API_URL}/country/getAllCity`).then((response) => {
        response.data.forEach((element) => {
          this.AllDepot.push(element);
        });
      });
    },
    GetAllCategoryHave() {
      axios
        .get(`${API_URL}/products/GetAllProductsCategory`)
        .then((response) => {
          this.Catégory = response.data;
        });
    },
    generatePDF() {
      html2pdf(this.$refs.document, {
        margin: [5, 10, 8, 10],
        filename: "document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
        jsPDF: { unit: "mm", format: "A4", orientation: "landscape" },
      });
    },
    getAllInvetaires() {
      this.dataIsLoaded = false;
      this.VideData = false;
      return axios
        .get(
          `${API_URL}/products/productInvetaireList?Product=${this.ProduitFiltred.toLocaleUpperCase()}&category=${this.CatégoryFilterd}&Depot=${this.DepotFilterd}`
        )
        .then((res) => {
          if (res.data.length == 0) this.VideData = true;

          this.dataIsLoaded = true;
          this.invetairesData = res.data;
        });
    },
  },
  mounted() {
    this.getAllInvetaires();
    this.GetAllCategoryHave();
    this.GetAllElementFiltred();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00abc4;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.paragrapheHeigth {
  height: 40px;
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 4%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0px auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>
