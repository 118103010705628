<template>
  <div class="editeRecovrementPopup">
            <p><span>Mode d'operation</span><span> : </span>{{ selectedRecovrement.key }}</p>
            <div>
                <table>
                    <tr>
                        <th>Banque</th>
                        <th>N°</th>
                        <th>Montant</th>
                        <th v-if="selectedRecovrement.key == 'Cheque' || selectedRecovrement.key == 'Effet'">Date</th>
                    </tr>
                    <tr>
                        <td>{{ selectedRecovrement.value.Bank }}</td>
                        <td>{{ selectedRecovrement.value.transactionNumber }}</td>
                        <td>{{ selectedRecovrement.value.Montant ? selectedRecovrement.value.Montant : selectedRecovrement.value.montant }}</td>
                        <td class="DateEcheanceTD" v-if="selectedRecovrement.key == 'Cheque' || selectedRecovrement.key == 'Effet'">
                            {{ selectedRecovrement.value.DateEcheance }}

                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
                                </v-btn>

                                </template>
                                <template v-slot:default="dialog">
                                <v-card>
                                    <v-card-text>
                                    <div class="text-h2 pa-12">
                                        <v-row justify="center">
                                            <v-date-picker v-model="pickerDateOne"></v-date-picker>
                                            <v-btn class="mt-5" @click="editeDate(selectedRecovrement.key, selectedRecovrement.value.key)">Modifier</v-btn>
                                        </v-row>
                                    </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </template>
                            </v-dialog>

                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dateListMeth(selectedRecovrement.key, selectedRecovrement.value.key)"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM128 288c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm32-128c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM128 384c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224z"/></svg>
                                </v-btn>

                                </template>
                                <template v-slot:default="dialog">
                                <v-card>
                                    <v-card-text>
                                    <div class="text-h2 pa-12">
                                        <v-alert
                                            border="bottom"
                                            color="pink darken-1"
                                            dark
                                                v-for="(dateList, index) in dateList" :key="index"
                                            >
                                            {{ dateList }}
                                        </v-alert>
                                    </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                    <v-btn
                                        text
                                        @click="dialog.value = false"
                                    >Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                                </template>
                            </v-dialog>
                        </td>
                    </tr>
                </table>
            </div>
  </div>
</template>

<script>
import axios from "axios";
import API_URL from "@/../config";

    export default {
        props: ["selectedRecovrement"],
        data() {
            return {
                pickerDateOne: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                pickerDateTwo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

                dateList: false,
                RecouvrementObject: false
            }
        },

        created() {
            // console.log(this.selectedRecovrement)
            this.RecouvrementObjectMethod()
        },

        methods: {
            RecouvrementObjectMethod() {
                axios.get(`${API_URL}/rapport/getOneRecovrementObject/${this.selectedRecovrement.value.id}`)
                .then(res => {
                    this.RecouvrementObject = res.data
                })
            },
            dateListMeth(OP, index) {
                if(OP == "Cheque") {
                    this.dateList = this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList
                }
                if(OP == "Effet") {
                    this.dateList = this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList
                }
            },
            editeDate(OP, index) {
                // console.log("test", this.RecouvrementObject.recouvrement[OP])
                if(OP == "Cheque") {
                    let currentDate = this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateEcheance

                    const [year, month, day] = this.pickerDateOne.split('-')
                    this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateEcheance = `${day}-${month}-${year}`

                    if(this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList)
                        this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList.push(currentDate)
                    else {
                        this.RecouvrementObject.recouvrement[OP].ChequeInfo[index].DateList = [currentDate]
                    }

                    this.$swal({
                        title: "Voulez vous modifier?",
                        showDenyButton: true,
                        confirmButtonText: `Modifier`,
                        denyButtonText: `Annuler`,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                        axios.put(`${API_URL}/rapport/updateRecouvrementDate/${this.selectedRecovrement.value.id}`, this.RecouvrementObject).then(() => {
                            this.RecouvrementObjectMethod()
                            this.$swal("cette operation a été modifier!", "", "success");
                            this.$emit('closePopup')
                        });
                        } else if (result.isDenied) {
                            this.$swal("cette operation a été annuler", "", "info");
                        }
                    });
                }
                if(OP == "Effet") {
                    // this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateEcheance = this.pickerDateTwo
                    const [year, month, day] = this.pickerDateOne.split('-')

                    // console.log("Effet", this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateEcheance)

                    let currentDate = this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateEcheance

                    this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateEcheance = `${day}-${month}-${year}`

                    if(this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList)
                        this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList.push(currentDate)
                    else {
                        this.RecouvrementObject.recouvrement[OP].EffetInfo[index].DateList = [currentDate]
                    }

                    this.$swal({
                        title: "Voulez vous modifier?",
                        showDenyButton: true,
                        confirmButtonText: `Modifier`,
                        denyButtonText: `Annuler`,
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                        axios.put(`${API_URL}/rapport/updateRecouvrementDate/${this.selectedRecovrement.value.id}`, this.RecouvrementObject).then(() => {
                            this.RecouvrementObjectMethod()
                            this.$swal("cette operation a été modifier!", "", "success");
                            this.$emit('closePopup')
                        });
                        } else if (result.isDenied) {
                            this.$swal("cette operation a été annuler", "", "info");
                        }
                    });
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

    $primaryFont: "Poppins", sans-serif;
    $primaryColor: #1cb6b6;
    $primaryBgColor2: rgba($primaryColor, 0.7);
    $secondary-color: #12d20d;
    $primaryColor2: #757575;
    $primary-background-color: #058B84;

    .editeRecovrementPopup {
        height: 80%;
        overflow-y: scroll;
        width:50%;
        left: 30%;
        position: absolute;
        z-index: 100;
        background: linear-gradient(180deg, #f3f3f3 30%, #dfdfdf 100%);
        border-radius: 10px;
        box-shadow: 0px 0px 11px -4px;
        text-align: center;
        padding:20px;
        top: 5%;

        p{
            font-size: 25px !important;
            font-weight: 600;
            text-align: start;
            line-height: 30px !important;
            text-align: center;
        }
        .ZoneTable{
            margin-bottom: 40px;
            p {
                color: #058B84;
            }
        }
        table{
            width:100%;
            th{
                background-color:#058B84;
                font-size: 20px;
                color:white;
                padding:10px 0px;
            }
            td{
                font-size: 20px;
                color:#058B84;
                padding:10px 0px;
                border: 1px solid #058B84;
                font-weight: bold;
            }
        }
        .QRCODE{
            padding: 40px;
        }
        .DownlodPdf{
            button{
                padding:10px 40px;
                border-radius: 30px;
                font-weight: bold;
                background: linear-gradient(90deg, rgba(5,139,132,1) 30%, rgba(0,0,0,1) 100%);
                color: #FFFFFF;
                display:block !important;
                margin: 10px auto;
                &:hover{
                    background: transparent;
                    color:#00abc4;
                    border:1px solid #00abc4;
                }

            }
        }

        table {
            tr {
                .DateEcheanceTD {
                    display: flex;
                    justify-content: space-around;
                    svg {
                        fill: $primaryColor2;
                        padding: 1%;
                        width: 33px;
                        height: 33px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .BalckPage {
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
.v-application .text-h2 {
  line-height: 0;
}
</style>
