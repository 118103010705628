<template>
  <div class="PopupNewClient tranferPopup">
    <h4 v-if="PropsTransfer.Condition == 'New'">Nouveau (Transfer)</h4>
    <h4 v-else-if="PropsTransfer.Condition == 'Update'">
      Mettre à jour (Transfer)
    </h4>
    <h4 v-else-if="PropsTransfer.Condition == 'Show'">Aperçu (Transfer)</h4>

    <div class="spinner" v-if="!getTheData">
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-if="getTheData">
      <div class="selectedTransferProduct">
        <select v-model="selectedProduct" @change="getStock">
          <option value="" disabled>selectionner une produit</option>
          <option
            :value="productList"
            v-for="(productList, index) in productsList"
            :key="index"
          >
            {{ productList }}
          </option>
        </select>

        <div class="justify-content-center d-flex mt-3 addBtn">
          <v-icon @click="addSelectedProductFun">mdi-plus-circle</v-icon>
        </div>
      </div>

      <div class="selectedDepot">
        <select v-model="selectedFrom" :disabled="selectedProduct == ''">
          <option value="" disabled>Dépot de sortir</option>
          <option
            :value="stockList"
            v-for="(stockList, index) in stocksList"
            :key="index"
          >
            {{ stockList.City }} ({{ stockList.Disponible }})
          </option>
        </select>

        <select v-model="selectedTo" :disabled="selectedProduct == ''">
          <option value="" disabled>Dépot d'entrer</option>
          <option
            :value="stockList"
            v-for="(stockList, index) in stocksList"
            :key="index"
          >
            {{ stockList.City }} ({{ stockList.Disponible }})
          </option>
        </select>
      </div>

      <div class="QuantityInputs">
        <div v-for="(selectedProd, index) in selectedProds" :key="index">
          <v-row class="d-flex align-baseline">
            <v-col cols="6">
              <p class="prodsName">{{ selectedProd.name }}</p>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="selectedProd.quantity"
                hide-details
                single-line
                type="number"
                placeholder="Saisse la quantité que vous voulez transférer"
              />
            </v-col>
            <v-col cols="2">
              <v-icon color="red" @click="deleteRowFun(index)"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </div>

        <v-textarea
          outlined
          name="input-7-4"
          label="entrer votre commentaire"
          v-model="commentValue"
        ></v-textarea>
      </div>

      <!-- autre visitedevelopement devis -->
      <div class="TransferBTN">
        <v-btn
          v-if="PropsTransfer.Condition == 'New'"
          rounded
          color="success"
          dark
          large
          @click="transferFunc"
        >
          Transférer
        </v-btn>
        <button v-else-if="PropsTransfer.Condition == 'Update'">
          Mettre à jour
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import Vue from "vue";
import axios from "axios";
import API_URL from "@/../config";

export default {
  emit: ["Close"],
  props: ["PropsTransfer"],
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
  },
  data() {
    return {
      getTheData: false,
      selectedProduct: "",
      selectedFrom: "",
      selectedTo: "",
      productsList: [],
      stocksList: [],
      QuantityValue: null,

      selectedProds: [],

      commentValue: ""
    };
  },
  created() {},
  computed: {},
  methods: {
    async transferFunc() {
      if (this.selectedProds.length == 0) {
        return Vue.$toast.open({
          message: "selectionner en moin une produit",
          type: "error",
        });
      }

      const errors = []

      this.selectedProds.map((element) => {
        if (element.quantity == 0)
          errors.push("selectionner la quantity des produits")
          // return Vue.$toast.open({
          //   message: "selectionner la quantity des produits",
          //   type: "error",
          // });
      });

      if(errors.length > 0) {
          return Vue.$toast.open({
            message: errors[0],
            type: "error",
          });
      }

      let transfertObjectAdded = []
      let transfertObjectMinus = []
      let productNamesList = []
      let productListDetails = []

      this.selectedProds.map(el => {
        productNamesList.push(el.name)

        transfertObjectMinus.push(
          {
            Name: el.name,
            City: el.to,
            quantity: el.quantity
          },
        )

        transfertObjectAdded.push(
          {
            Name: el.name,
            City: el.from,
            quantity: el.quantity
          },
        )
      })

      await axios.post(`${API_URL}/products/listOfProductFromNames`, {productsName: productNamesList})
      .then(res => {
        productListDetails = res.data
      })

      const addNewStockValue = {
        FromD: transfertObjectAdded,
        ToD: transfertObjectMinus,
        Comment: this.commentValue,
        ProductsArr: productListDetails,
        TransferV: "1.0.0"
      }

      await axios.post(`${API_URL}/Stock/updateStock`, {selectedProducts: transfertObjectAdded})
      .then(() => {
        Vue.$toast.open({
          message: "Transférée",
          type: "success",
        });
      })
      .catch((err) => {
        Vue.$toast.open({
          message: err.response.data.error,
          type: "error",
        });
      });

      await axios.post(`${API_URL}/Stock/updateStockAvoir`, {selectedProducts: transfertObjectMinus})
      .then(() => {
        Vue.$toast.open({
          message: "Transférée",
          type: "success",
        });
      })
      .catch((err) => {
        Vue.$toast.open({
          message: err.response.data.error,
          type: "error",
        });
      });

      await axios
        .post(`${API_URL}/Stock/addStock`, addNewStockValue)
        .then(() => {
          Vue.$toast.open({
            message: "data added",
            type: "success",
          });
        })
        .catch((err) => {
          Vue.$toast.open({
            message: err.response.data.error,
            type: "error",
          });
        });

      await axios
      .post(`${API_URL}/rapport/clearRedisData?keys=["ProductByDepotDpP", "GestionOP", "LVJ", "EC"]`)
          .then(() => {
            Vue.$toast.open({
              message: "data refreshed",
              type: "success",
            });
            this.$emit("Close");
          })
          .catch((err) => {
            Vue.$toast.open({
              message: err.response.data.error,
              type: "error",
            });
        });
    },
    getStock() {
      this.getTheData = false;
      axios
        .get(`${API_URL}/products/productFilterByName/${this.selectedProduct}`)
        .then((response) => {
          response.data.map((element) => {
            this.stocksList = element.Stock;
            this.getTheData = true;
          });
        });
    },
    getProductsList() {
      axios.get(`${API_URL}/products/productsByName`).then((response) => {
        this.productsList = response.data;
        this.getTheData = true;
      });
    },
    addSelectedProductFun() {
      console.log(this.selectedFrom.City)
      console.log(this.selectedTo.City)

      let isExist = this.selectedProds.find((element) => {
        if (element.name == this.selectedProduct) return true;
        false;
      });

      if (isExist)
        Vue.$toast.open({
          message: "cette produit déjà selécionnée",
          type: "error",
        });
      else if (this.selectedProduct == "")
        Vue.$toast.open({
          message: "selécionner une prduit",
          type: "error",
        });
      else if(!this.selectedFrom.City || !this.selectedTo.City || this.selectedFrom.City == "" || this.selectedTo.City == "") {
        Vue.$toast.open({
          message: "selécionner les depots",
          type: "error",
        });
      }
      else
        this.selectedProds.push({ name: this.selectedProduct, quantity: 0, from: this.selectedFrom.City, to: this.selectedTo.City });
    },
    deleteRowFun(index) {
      this.selectedProds.splice(index, 1);
    },
  },
  mounted() {
    this.getProductsList();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;

.tranferPopup {
  .addBtn {
    i {
      cursor: pointer;
      font-size: 40px;
    }
  }
  position: relative;
  h6 {
    font-family: $primaryFont;
    font-size: 20px;
    font-weight: 700;
    color: #142048;
  }
  select {
    border: 1px solid #035a7f;
    width: 80%;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    margin: 0% 10%;
    &:focus-visible {
      outline: unset;
      border: 1px solid #035a7f;
    }
  }
  .selectedTransferProduct {
    width: 100%;
  }
  .selectedDepot {
    margin: 1% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 5%;
  }

  .QuantityInputs {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1% 20%;
    flex-direction: column;
    i {
      cursor: pointer;
    }
    .prodsName {
      background-color: #a7a1a1;
      padding: 1%;
      border-radius: 20px;
      color: white;
      font-weight: 500;
    }
  }

  .TransferBTN {
    margin-top: 5%;
    width: 100%;
    justify-content: center;
    padding: 0% 20%;
    button {
      width: 100%;
    }
  }
}

.PopupNewClient {
  position: absolute;
  width: 40%;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 11px -4px black;
  left: 30%;
  top: 10%;
  z-index: 100000;
  overflow: auto;
  height: 80%;
  h4 {
    font-family: $primaryFont;
    font-size: 30px;
    font-weight: bold;
    color: $primary-background-color;
    padding-bottom: 30px;
  }
  .Form {
    display: flex;
    align-items: start;
    justify-content: space-between;
    .LeftInputs,
    .RightInputs {
      width: 100%;
      .InputZone {
        margin: 20px 0px;
        h6 {
          font-family: $primaryFont;
          font-size: 20px;
          font-weight: 700;
          color: #142048;
        }
      }
      .InputFaild {
        border: 1px solid #035a7f;
        width: 90%;
        padding: 6px;
        border-radius: 6px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
  .TCP {
    margin-bottom: 20px;
    h6 {
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048;
    }

    select {
      border: 1px solid #035a7f;
      width: 95%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .BtnPopUp {
    width: 95%;
    text-align: end;
  }
  .Personne {
    display: flex;
    align-items: center;
    p {
      margin-right: 50px;
      font-family: $primaryFont;
      font-size: 20px;
      font-weight: 700;
      color: #142048 !important;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #142048;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @media screen and (max-width: 1840px) {
  }
  @media screen and (max-width: 1640px) {
    padding: 20px 40px !important;
    width: 60%;
    top: 2%;
    left: 20%;
  }
  @media screen and (max-width: 1440px) {
    h4 {
      font-size: 20px;
      padding-bottom: 10px !important;
    }
    h6 {
      font-size: 15px !important;
    }
    p {
      font-size: 15px !important;
    }
    input,
    select,
    textarea {
      padding: 3px !important;
    }
    .InputZone {
      padding: 3px;
      margin: 10px 0px !important;
    }
  }
}
</style>
