<template>
    <div class="RapportDeVente">
            <h2>Charge vehicule</h2>
            <div>
                <div class="ZoneTable">
                    <h2 style="margin:30px 0px;"></h2>
                    <table>
                        <tr>
                            <th>Date</th>
                            <th>Détails</th>
                            <th>Responsable</th>
                            <th>Véhicule</th>
                            <th>Etat</th>
                            <th>Description</th>
                            <th>Montant</th>
                        </tr>
                        <tr>
                            <td>{{ OneOfCV.date }}</td>
                            <td>{{ OneOfCV.details }}</td>
                            <td>{{ OneOfCV.responsable.Name }}</td>
                            <td>{{ OneOfCV.responsable.UserVehicul }}</td>
                            <td>{{ OneOfCV.isAproved ? "Aprovée" : "Non Aprovée" }}</td>
                            <td>{{ OneOfCV.description }}</td>
                            <td>{{ OneOfCV.montant }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="DownlodPdf">
                <button @click="showUploadedFile(OneOfCV.bonFile)">Telecharger Bon</button>
                <button @click="showGeneratedPdf(OneOfCV._id)">Telecharger PDF</button>
            </div>
    </div>
</template>

<script>

import Swal from 'sweetalert2'
import axios from "axios";
import API_URL from "../../../config";
import SOCKETS_URL from "../../../configSocketsURL";

export default {
    props:['RapportId', 'OneOfCV'],
  data() {
    return {

    };
  },
  created() {

  },
  methods: {
    showUploadedFile(file) {
        window.open(`${SOCKETS_URL}/${file}?token=ALDtIanysTac2023`, "_blank");
    },
    showGeneratedPdf(ID) {
        window.open(`${API_URL}/companyFees/generateCompanyFeesPdf/${ID}?token=ALDtIanysTac2023`, "_blank");
    },
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #058B84;
.RapportDeVente{
    height: 800px;
    overflow-y: scroll;
    width:50%;
    left: 30%;
    position: absolute;
    z-index: 100;
    background: linear-gradient(180deg, #f3f3f3 30%, #dfdfdf 100%);
    border-radius: 10px;
    box-shadow: 0px 0px 11px -4px;
    text-align: center;
    padding:20px;
    top: 5%;
    // @media (max-width: 1600px) {
    //     height: 500px;
    // }
    h2,h1{
        font-family:$primaryFont ;
        font-size: 25px;
        font-weight:600;
        color:$primary-background-color;
    }
    h1{
        font-size: 40px !important;
        font-weight: bold;
    }
    p{
        font-size: 25px !important;
        font-weight: 600;
        text-align: start;
        line-height: 30px !important;
    }
    .ZoneTable{
        margin-bottom: 40px;
        p {
            color: #058B84;
        }
    }
    table{
        width:100%;
        th{
            background-color:#058B84;
            font-size: 20px;
            color:white;
            padding:10px 0px;
        }
        td{
            font-size: 20px;
            color:#058B84;
            padding:10px 0px;
            border: 1px solid #058B84;
            font-weight: bold;
        }
    }
    .QRCODE{
        padding: 40px;
    }
    .DownlodPdf{
        button{
            padding:10px 40px;
            border-radius: 30px;
            font-weight: bold;
            background: linear-gradient(90deg, rgba(5,139,132,1) 30%, rgba(0,0,0,1) 100%);
            color: #FFFFFF;
            display:block !important;
            margin: 10px auto;
            &:hover{
                background: transparent;
                color:#00abc4;
                border:1px solid #00abc4;
            }

        }
    }
}
</style>

